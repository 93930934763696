import React from "react";
import muda_asistente_1 from "../../res/muda_asistente_1.png";

export default class PropertyTypeEdit extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        this.state = {
            screen:0
        }
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <div className={`fade-in mt-1 ${this.props.fade?"fade-out":""}`}>
            <button className=" btn btn-back-left" onClick={() => {
                if(this.state.screen === 0){
                this.props.back();
                }else if(this.state.screen ===1){
                   if(this._mounted){
                       this.setState({
                           screen:0
                       })
                   }
                }else if(this.state.screen === 2){
                    if(this._mounted){
                        this.setState({
                            screen:1
                        })
                    }
                }else if(this.state.screen ===3){
                    if(this._mounted){
                        this.setState({
                            screen:0
                        })
                    }
                }else if(this.state.screen ===4){
                    if(this._mounted){
                        this.setState({
                            screen:3
                        })
                    }
                }
            }}>
                <i className="material-icons align-middle">arrow_back</i>
            </button>
            {this.state.screen === 0 || this.state.screen ===1? <div className="muda-planner flex-center">
                   <div className="muda-planner-img mr-3">
                       <img src={muda_asistente_1} />
                   </div>
                   <div className="muda-planner-info">
                       <h5>Eduardo</h5>

                       <div className="muda-planner-stars flex-left mt-2">
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle">grade</i>
                       </div>

                       <p className="mt-2">MudaPlanner</p>
                   </div>
               </div>:null}

        {this.state.screen === 0 || this.state.screen ===1?<div className="flex-center"><h1 className="main-header text-center mt-5">{this.props.header}</h1></div>:null}

               {this.state.screen === 0?<div className="flex-center"><div className="login-form mt-4">
                   <div className="form-group">
                   <div className="input-group mb-3 cursor-pointer" onClick={()=> {
                       this.props.handleSelection(0)
                   }}>
                       <input className="form-control cursor-pointer"  readOnly={true} placeholder="Casa" type="text"/>
                       {this.props.house === true?<div className="input-group-append">
                        <span className="input-group-text"><i className="material-icons align-middle checked">check_circle</i></span>
                        </div>:null}
                       </div>
                   </div>

                   <div className="form-group">
                   <div className="input-group mb-3 cursor-pointer" onClick={()=> {
                       this.props.handleSelection(1)
                   }}>
                       <input className="form-control cursor-pointer"  readOnly={true} placeholder="Departamento u Oficina" type="text"/>
                       {this.props.department === true?<div className="input-group-append">
                        <span className="input-group-text"><i className="material-icons align-middle checked">check_circle</i></span>
                        </div>:null}
                       </div>
                   </div>

                   <div className="form-group">
                   <div className="input-group mb-3 cursor-pointer" onClick={()=> {
                       this.props.handleSelection(2)
                   }}>
                       <input className="form-control cursor-pointer"  readOnly={true} placeholder={this.props.type === ""?"Otro":this.props.type} type="text"/>
                       {this.props.other === true?<div className="input-group-append">
                        <span className="input-group-text"><i className="material-icons align-middle checked">check_circle</i></span>
                        </div>:null}
                       </div>
                   </div>

                  
                  <div className="flex-center">

                  <button className="btn btn-selectable mr-2" onClick={() => {
                        this.props.changeSequenceOmit(2)
                    }}>
                        <span>OMITIR</span>
                    </button>

                   <button className={`btn ${this.props.valid?"btn-custom-1":"btn-disabled"} mt-4 btn-block`} onClick={() => {
                    
                       if(this.props.house === true){
                           this.props.changeSequence(2);
                       }else {
                           if(this.props.department === true){
                               if(this._mounted){
                                   this.setState({
                                       screen:1
                                   })
                               }
                           }
                           if(this.props.other === true && this.props.type === ""){
                               if(this._mounted){
                                   this.setState({
                                       screen:3
                                   })
                               }
                           }else if(this.props.other === true && this.state.type !== "" && this.props.floor2 != ""){
                               this.props.changeSequence(2)
                           }else if(this.props.floor2 == "" && this.props.other === true){
                            if(this._mounted){
                                this.setState({
                                    screen:3
                                })
                            }
                           }
                       }
                   }}>
                       <span>CONTINUEMOS</span>
                   </button>

                   </div>

               </div></div>:null}

               {this.state.screen === 1?<div className="flex-center"><div className="login-form mt-4">
                 

                   <div className="form-group">
                   <div className="input-group mb-3 cursor-pointer">
                       <input className="form-control cursor-pointer"  readOnly={true} placeholder="Departamento u Oficina" type="text"/>
                      <div className="input-group-append">
                        <span className="input-group-text"><i className="material-icons align-middle checked">check_circle</i></span>
                        </div>
                       </div>
                   </div>

                   <div className="form-group">
                   <div className="input-group mb-3 cursor-pointer" onClick={e => {
                       if(this._mounted){
                           this.setState({
                               screen:2
                           })
                       }
                   }}>
                       <input className="form-control cursor-pointer"  readOnly={true} placeholder={`${this.props.floor !== ""?"Piso " + this.props.floor:"Piso #"}`} type="text"/>
                       {this.props.other === true?<div className="input-group-append">
                        <span className="input-group-text"><i className="material-icons align-middle checked">check_circle</i></span>
                        </div>:null}
                       </div>
                   </div>

                   <div className="flex-center">
                       <p>Se puede utilizar elevador?</p> 
                       <button className={`btn ml-3 ${this.props.elevator === true?"btn-selectable-active":"btn-selectable"}`} onClick={() => {
                           this.props.changeElevator(true)
                       }}>
                           <span>Si</span>
                       </button>
                       <button className={`btn ml-2 ${this.props.elevator === false?"btn-selectable-active":"btn-selectable"}`} onClick={() => {
                           this.props.changeElevator(false)
                       }}>
                           <span>No</span>
                       </button>
                   </div>

                 

                   <button className={`btn ${this.props.floor !== "" && this.props.elevator !== null?"btn-custom-1":"btn-disabled"} mt-4 btn-block`} onClick={() => {
                       if(this.props.floor !== ""){
                           if(this.props.elevator !== null){
                               this.props.changeSequence(2);
                           }else {
                               this.props.addToast("Debes especificar si funciona el elevador or no");
                           }
                       }else {
                           this.props.addToast("Debes escoger un piso");
                       }
                   }}>
                       <span>CONTINUEMOS</span>
                   </button>

               </div></div>:null}


               {this.state.screen === 2?<div className="flex-center"><div className="floor-wrapper mt-4">
                     <h6 className="text-center">Selecciona el Piso</h6>

                          <div className="flex-center mt-2">
                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("PB");
                                 if(this._mounted){
                                     this.setState({
                                         screen:1
                                     })
                                 }
                             }}>
                                 <span>PB</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("01");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>01</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("02");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>02</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("03");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>03</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("04");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>04</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("05");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>05</span>
                             </button>
                         </div>
                   

                   
                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("06");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>06</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("07");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>07</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("08");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>08</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("09");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>09</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("10");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>10</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("11");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>11</span>
                             </button>
                         </div>
                     

                    
                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("12");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>12</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("13");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>13</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("14");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>14</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("15");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>15</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("16");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>16</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("17");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>17</span>
                             </button>
                         </div>
                    

                     
                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("18");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>18</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("19");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>19</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("20");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>20</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("21");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>21</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("22");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>22</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("23");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>23</span>
                             </button>
                         </div>
                    


                   
                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("24");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>24</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("25");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>25</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("26");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>26</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("27");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>27</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("28");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>28</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("29");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>29</span>
                             </button>
                         </div>
                     


                     
                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("30");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>30</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("31");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>31</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("32");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>32</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("33");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>33</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("34");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>34</span>
                             </button>
                         </div>

                         <div className="">
                             <button className="btn btn-selectable" onClick={() => {
                                 this.props.changeFloor("+");
                                 if(this._mounted){
                                    this.setState({
                                        screen:1
                                    })
                                }
                             }}>
                                 <span>+</span>
                             </button>
                         </div>
                     </div>
               </div></div>:null}


               {this.state.screen === 3?<div className="flex-center"><div className="other-wrapper mt-4">
                   <button className="btn btn-selectable btn-block mt-1" onClick={() => {
                       this.props.setType("Bodega");
                       if(this._mounted){
                           this.setState({
                               screen:4
                           })
                       }
                   }}>
                       <span>Bodega</span>
                   </button>

                   <button className="btn btn-block btn-selectable mt-1" onClick={() => {
                       this.props.setType("Clinica u Hospital");
                       if(this._mounted){
                        this.setState({
                            screen:4
                        })
                    }
                   }}>
                       <span>Clínica u Hospital</span>
                   </button>

                   <button className="btn btn-block btn-selectable mt-1" onClick={() => {
                       this.props.setType("Condominio");
                       if(this._mounted){
                        this.setState({
                            screen:4
                        })
                    }
                   }}>
                       <span>Condominio</span>
                   </button>

                   <button className="btn btn-block btn-selectable mt-1" onClick={() => {
                       this.props.setType("Escuela");
                       if(this._mounted){
                        this.setState({
                            screen:4
                        })
                    }
                   }}>
                       <span>Escuela</span>
                   </button>

                   <button className="btn btn-block btn-selectable mt-1" onClick={() => {
                       this.props.setType("Hacienda");
                       if(this._mounted){
                        this.setState({
                            screen:4
                        })
                    }
                   }}>
                       <span>Hacienda</span>
                   </button>

                   <button className="btn btn-block btn-selectable mt-1" onClick={() => {
                       this.props.setType("Plaza Comercial");
                       if(this._mounted){
                        this.setState({
                            screen:4
                        })
                    }
                   }}>
                       <span>Plaza Comercial</span>
                   </button>

                   <button className="btn btn-block btn-selectable mt-1" onClick={() => {
                       this.props.setType("Taller");
                       if(this._mounted){
                        this.setState({
                            screen:4
                        })
                    }
                   }}>
                       <span>Taller</span>
                   </button>

                   <button className="btn btn-block btn-selected mt-1" onClick={() => {
                       this.props.setType("Otro");
                       if(this._mounted){
                        this.setState({
                            screen:4
                        })
                    }
                   }}>
                       <span>Otro</span>
                   </button>
               </div></div>:null}

               {this.state.screen === 4?<div className="flex-center"><div className="other-wrapper mt-4">
                   <div className="row">
                       <div className="col-6">
                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Estacionamiento");
                               if(this._mounted){
                                   this.setState({
                                       screen:0
                                   })
                               }
                           }}>
                               <span>Estacionamiento</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Sotano");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Sotano</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Planta Baja");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Planta Baja</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #1");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #1</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #2");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #2</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #3");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #3</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #4");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #4</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #5");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #5</span>
                           </button>
                       </div>

                       <div className="col-6">
                       <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #6");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #6</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #7");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #7</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #8");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #8</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #9");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #9</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #10");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #10</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #11");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #11</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Nivel #12");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Nivel #12</span>
                           </button>

                           <button className="btn btn-selectable mt-1 btn-block" onClick={() => {
                               this.props.setFloor2("Otro");
                               if(this._mounted){
                                this.setState({
                                    screen:0
                                })
                            }
                           }}>
                               <span>Otro</span>
                           </button>
                       </div>
                   </div>
               </div></div>:null}
        </div>
    }
}