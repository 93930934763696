import React from "react";

class HowItWorks extends React.Component {
    _mounted;

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <div className="modal-wrapper fade-in">
            <button className="btn btn-close-modal" onClick={() => {
                this.props.close()
            }}>
                <i className="material-icons align-middle">clear</i>
            </button>
            <div className="modal-content flex-center">
                <div>
                    <h2 className="text-center">COMO FUNCIONA</h2>

                    <p className="mt-4 text-center">
                    Mudatodo te hace el proceso de tu mudanza más fácil, pero sobre todo al mejor precio y de forma segura.<br/><br/>

Si estás leyendo esto, es porque ya enviaste tu solicitud de mudanza, ahora lo que sigue es esperar unos minutos para que nuestros mudapartnes revisen tu solicitud y puedan enviarte su propuesta.<br/><br/>

<b>¿Ya recibiste algunas propuestas?</b><br/><br/>
Excelente, ahora selecciona una y utiliza el chat para preguntar cualquier detalles con el representante de esa
propuesta.<br/><br/>
Recuerda que no esta permitido enviar datos de contacto, como teléfono, email o cualquier otro medio, nosotros nos encargaremos de esa parte. Y es importante que reportes a cualquier agente que te solicite información personal o bien que te pida que lo contactes por fuera de la plataforma. Todo acuerdo hecho fuera de la plataforma, te desprotege de la garantía de mudatodo.<br/><br/>

<b>Reservación del Servicio</b><br/><br/>
Si ya has seleccionado quien te va a realizar el servicio, es hora de Aceptar la Propuesta, así que presiona el 
botón verde superior derecho, el cual te permitirá ver el procedimiento de reservación y podrás descargar tu cotización en PDF.


                    </p>
                </div>
            </div>
        </div>
    }
}


export default HowItWorks;