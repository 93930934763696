import React,{useState, useEffect} from "react";

import {NavLink} from "react-router-dom"

import logo_short from "../../res/logo_short.png";

import firebase from "../../utils/firebaseSetup";

import ModalClientRequest from "./ModalClientRequest";

import formatNumber from "../../utils/formatNumber";

export default function SideNav(props){

    const [open, setOpen] = useState(false);

    const [closing, setClosing] = useState(false);

    useEffect(() => {

        if(props.small){
            window.document.body.style.overflow ="hidden";
        }

        return () => {

            if(props.small){
            window.document.body.style.overflow ="visible";
            }
        }
    }, [])

    const standarClose = () => {
        setClosing(true)
        setTimeout(() => {
            props.close()
        }, 350)
    }
  

    return <div className="side-nav">

        
        {open === true?<ModalClientRequest
            addToast={props.addToast}
            closeModal={() => {
                setOpen(false)
            }}
             lead={props.leadData}
             userData={props.userData}
            />:null}

            {props.small?<div className="backdrop" onClick={() => {

                setClosing(true)
        setTimeout(() => {
            props.close()
        }, 350)



}}> </div>:null}

       
         <div className={`side-nav-wrapper ${closing?"close-now":""} shadow`}>
        <div className="logo-section">

            <div className="flex-center">
                <img className="logo-item" src={logo_short}/>
            </div>

        </div>

        <div className="body-section">

            <p className="mb-0"><b>Monto confirmado</b></p>
            <p className="mb-0 mt-2 confiermed-amount">${formatNumber(props.userData.approvedAmount !== undefined?props.userData.approvedAmount:0)}</p>

            <ul className="mt-3 menu-section">

                <li className="menu-item">
                    <NavLink to="/" className={({isActive}) => {
                        if(isActive){
                            return "is-active"
                        }
                    }} onClick={() => {
                        if(props.small){
                            standarClose();
                        }
                    }}>
                        <span>
                            <i className="material-icons align-middle">home</i>
                        </span>
                        <span>
                            INICIO
                        </span>
                    </NavLink>

                </li>

                <li className="menu-item">
                    <NavLink to="/my-account" className={({isActive}) => {
                        if(isActive){
                            return "is-active"
                        }
                    }} onClick={(e) => {
                      //  e.preventDefault();
                        if(props.small){
                            standarClose();
                        }
                    }}>
                        <span>
                            <i className="material-icons align-midle">account_circle</i>
                        </span>
                        <span>
                            MI PERFIL
                        </span>
                    </NavLink>
                </li>

                <li className="menu-item">
                    <a onClick={(e) => {
                        
                        e.preventDefault();
                        props.openNotifications();

                        if(props.small){
                            standarClose();
                        }
                     //   props.openFaq();
                    }}>
                        <span>
                            <i className="material-icons align-midle">notifications</i>
                        </span>
                        <span>
                            NOTIFICACIONES
                        </span>
                    </a>
                </li>

                <li className="menu-item">
                    <a  onClick={e => {
                        e.preventDefault();
                         props.openModalRequest();
                         
                         if(props.small){
                             standarClose();
                         }
                    }}>
                        <span>
                            <i className="material-icons align-midle">book</i>
                        </span>
                        <span>
                            MI SOLICITUD
                        </span>
                    </a>
                </li>

                <li className="menu-item">
                    <a onClick={(e) => {
                        e.preventDefault();
                        props.openFaq();
                        standarClose();
                    }}>
                        <span>
                            <i className="material-icons align-midle">live_help</i>
                        </span>
                        <span>
                            PREGUNTAS Y RESPUESTAS
                        </span>
                    </a>
                </li>

                <li className="menu-item">
                    <NavLink to="/why-us" className={({isActive}) => {
                        if(isActive){
                            return "is-active"
                        }
                    }} onClick={(e) => {
                        //e.preventDefault();
                        if(props.small){
                            standarClose();
                        }
                    }}>
                        <span>
                            <i className="material-icons align-midle">local_shipping</i>
                        </span>
                        <span>
                            ¿PORQUE NOSOTROS?
                        </span>
                    </NavLink>
                </li>

                <li className="menu-item">
                    <a target="_blank" href={`https://wa.link/ooyb7n`}>
                        <span>
                            <i className="material-icons align-midle">local_shipping</i>
                        </span>
                        <span>
                           FACTURACIÓN
                        </span>
                    </a>
                </li>

                <li className="menu-item">
                    <a to="/" target="_blank" href={`https://api.whatsapp.com/send?phone=5219983047709&text=Hola,%20quiero%20dar%20seguimiento%20a%20mi%20solicitud%20de%20mudanza,%20mi%20nombre%20es%20${props.name}`}>
                        <span>
                            <i className="material-icons align-midle">whatsapp</i>
                        </span>
                        <span>
                           AYUDA POR WHATSAPP
                        </span>
                    </a>
                </li>

                <li className="menu-item">
                    <a className={`${props.userData.stage !== 1?"item-disabled":""}`} onClick={e => {
                        if(props.userData.stage === 1){
                        e.preventDefault();
                        props.rateMudapartner();

                        if(props.small){
                            standarClose();
                        }
                    }else {
                        props.addToast("Debes aceptar una propuesta para calificar al mudapartner")
                    }
                    }}>
                        <span>
                            <i className="material-icons align-midle">star</i>
                        </span>
                        <span>
                           CALIFICAR SERVICIO
                        </span>
                    </a>
                </li>

                <li className="menu-item">
                    <NavLink to="/terms" onClick={() => {
                        if(props.small){
                            standarClose();
                        }
                    }}>
                        <span>
                            <i className="material-icons align-midle">offline_pin</i>
                        </span>
                        <span>
                           TÉRMINOS Y CONDICIONES
                        </span>
                    </NavLink>
                </li>

                <li className="menu-item mt-3">
                    <a onClick={e => {
                        e.preventDefault();
                        
                        firebase.auth().signOut();
                    }}>
                        <span>
                            <i className="material-icons align-midle">power_settings_new</i>
                        </span>
                        <span>
                           SALIR 
                        </span>
                    </a>
                </li>

            </ul>
        </div>

        </div>
    </div>
}