import React from "react";
import muda_asistente_1 from "../../res/muda_asistente_1.png";
import GoogleApiWrapper from "../SignUp/MapComponent";

import firebase from "../../utils/firebaseSetup";

import $ from "jquery";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';


const googleMapsClient = require('@google/maps').createClient({
  key: 'AIzaSyDybIuhJCo4f30KW_e5sNqIwEUuF8FNsPU'
});



export default class PositionEdit extends React.Component {
    _mounted;
    constructor(props){
        super(props);

        this.state = {
            valid:false,
            address:"",
            position:null,
            session:null,
            results:[],
            pending:false
        }
    }

    componentDidMount(){
        this._mounted = true;

        if(this._mounted){
          this.setState({
            session:firebase.firestore().collection("sessions").doc().id,
            position:{
                lat:this.props.position.x,
                lng:this.props.position.y
            },
            valid:true,
          })
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.props.position.x !== nextProps.x || this.props.position.y !== nextProps.y){
        if(this._mounted){
            this.setState({
            //  session:firebase.firestore().collection("sessions").doc().id,
              position:{
                  lat:nextProps.position.x,
                  lng:nextProps.position.y
              },
             // valid:true,
            })
          }
        }
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    handleRequestAutocomplete = (str) => {
      str = str.replace(/ +(?= )/g,'+');

      if(this._mounted){
        this.setState({
          pending:true
        })
      }

      
      
      $.ajax({url:"https://mudatodo-8f658.uc.r.appspot.com/places-autocomplete", crossDomain:true, 
      data:{
          token:this.state.session,
          input:str
      },
     method:"POST",
  success:(response) =>{
     if(this._mounted){
         this.setState({
             pending:false,
             
         })
  
         
     }

     console.log(response);


     let results = [];

     for(let i =0; i < response.result.length; i++){
       results.push(response.result[i].description);
      // this.props.addToast(response.result[i].description)
     }

     console.log(results);
     
     if(this._mounted){
       this.setState({
         results:results
       })
     }

     

  
  }, error:(err) => {
      if(this._mounted){
          this.setState({
              pending:false
          })
      }

      console.log(err);
     // this.props.addToast("Algo salió mal");
  }})
    }

    handleChange = address => {
        this.setState({ address });
      };
     
      handleSelect = address => {
        this.setState({ address,valid:false });

        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
              console.log('Success', latLng)
              this.setState({position:latLng, valid:true})
              this.props.setCoords({
                  x:latLng.lat,
                  y:latLng.lng
              })
              this.props.setAddress(address);
            })
          .catch(error => console.error('Error', error));
      };

    handleLocationChange ({ position, address, places }) {
 
        // Set new location
        this.setState({ position, address });
        console.log(position, address);
      }
    render(){
        return <div className={`fade-in mt-1 ${this.props.fade?"fade-out":""}`}>
            <button className=" btn btn-back-left" onClick={() => {
                this.props.back();
            }}>
                <i className="material-icons align-middle">arrow_back</i>
            </button>
            <div className="muda-planner flex-center">
                   <div className="muda-planner-img mr-3">
                       <img src={muda_asistente_1} />
                   </div>
                   <div className="muda-planner-info">
                       <h5>Eduardo</h5>

                       <div className="muda-planner-stars flex-left mt-2">
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle">grade</i>
                       </div>

                       <p className="mt-2">MudaPlanner</p>
                   </div>
               </div>

               <div className="flex-center"><h1 className="main-header text-center mt-5">{this.props.header}</h1></div>

               <div className="mt-4 flex-center">
               <GoogleApiWrapper position={this.state.position} setAddress={(address) => {
                 if(this._mounted){
                   this.setState({
                     address:address
                   })
                 }
                 this.props.setAddress(address)
                }} addToast={this.props.addToast} unvalidate={() => {
                   if(this._mounted){
                       this.setState({
                           valid:false
                       })
                   }
               }} validate={() => {
                   if(this._mounted){
                       this.setState({
                           valid:true
                       })
                   }
               }}  setCoords={this.props.setCoords}/>
               </div>

               <div className="flex-center mt-4">
               <div className="login-form">
                   <div className="form-group autocomplete-block">


                     {this.state.results.length > 0?<div className="results shadow-sm">
                       {this.state.results.map((e,i) => {
                         return <div className="result-block" key={i} onClick={() => {
                           this.handleSelect(e);

                           if(this._mounted){
                             this.setState({
                               results:[]
                             })
                           }
                         }}>
                           <p className="mb-0">{e}</p>
                         </div>
                       })}
                     </div>:null}


                     <input className="form-control" placeholder={this.props.placeholder} value={this.state.address} onChange={e => {
                       if(this._mounted){
                         this.setState({
                           address:e.currentTarget.value
                         })
                       }

                       this.handleRequestAutocomplete(e.currentTarget.value)
                     }}/>
              
                    {true !== true?<input type="text" className="form-control cursor-pointer" disabled={true} value={this.props.address}/>:null}

                    {true !== true?<PlacesAutocomplete
                    
                    searchOptions={{componentRestrictions:{
                        country:"mx"
                    }}}
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
            value={this.state.address}
            
              {...getInputProps({
                placeholder: this.props.placeholder,
                className: 'location-search-input form-control',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>:null}
              
                </div>

                <div className="flex-center">
                    <button className="btn btn-selectable mr-2" onClick={() => {
                        this.props.changeSequenceOmit(1)
                    }}>
                        <span>OMITIR</span>
                    </button>
               <button className={`btn ${this.state.valid?"btn-custom-1":"btn-disabled"} mt-4 btn-block`} onClick={() => {
                       if(this.state.valid){
                           this.props.changeSequence(1)
                       }else {
                           this.props.addToast("Aun no ha cargado el mapa");
                       }
                    }}>
                        <span>CONTINUEMOS</span>
                    </button>
                </div>

               </div>
               </div>

        
        </div>
    }
}