import React from "react";

import bed from "../../res/bed.png";
import table from "../../res/table.png";
import cajonera from "../../res/cajonera.png";
import estufa from "../../res/estufa.png";
import bici from "../../res/bici.png";
import TV_ICON from "../../res/TV_ICON.png";
import jardin from "../../res/jardin.png";
import sillones from "../../res/sillones.png";
import caja2 from "../../res/caja2.png";
import car from "../../res/car.png";

import ModalInventory from "./ModalInventory";
import {DatePicker} from "@blueprintjs/datetime";
import firebase from "../../utils/firebaseSetup";
import LoadingFeedback from "../Loaders/LoadingFeedback";

import PropertyTypeEdit from "./PropertyTypeEdit";
import PositionEdit from "./PositionEdit";
import RecolectionDistanceEdit from "./RecolectionDistanceEdit";

export default class ModalClientRequest extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        this.state = {
            screen:0,
            sequence:0,
            updated:false,

            modal:false,
            header:"",
            vol:0,
            onlyToggle:false,
            leftElements:[],
            middleElements:[],
            rightElements:[],
            target:"",
            editDate:false,

            commentEdit:false,
            newComment:"",
            commentChanged:false,

            details:[],
            editDetails:false,
            detailsChanged:false,

            propertyType:{
                home:false,
                department:false,
                other:false,
                valid:false
            },

            propertyTypeChanged:false,

            departmentData:{
                floor:"",
                elevator:null
            },

            otherData:{
                type:"",
                floor:""
            },

            position:{
                x:0,
                y:0
            },
            positionChanged:false,
            address:"",

            recolectionDistance:{
                firstDistance:false,
                secondDistance:false,
                thirdDistance:false,
                valid:false
            },

            recolectionDistanceChanged:false,


            propertyTypeD:{
                home:false,
                department:false,
                other:false,
                valid:false
            },

            propertyTypeDChanged:false,

            departmentDataD:{
                floor:"",
                elevator:null
            },

            otherDataD:{
                type:"",
                floor:""
            },

            positionD:{
                x:0,
                y:0
            },

            positionDChanged:false,

            addressD:"",

            recolectionDistanceD:{
                firstDistance:false,
                secondDistance:false,
                thirdDistance:false,
                valid:false
            },

            recolectionDistanceDChanged:false,


            images:[],
            resetting:false,
            feedback:"",
            valid:false,
            date:null,

            inventory:{
                valid:false,
                vol:0,
                comment:"",
                loaded:false,
                bed:{
                    selected:false,
                    header:"RECÁMARAS",
                    vol:0,
                    leftElements:[
                        {
                            title:"Cama King Size (Base y colchón)",
                            quantity:0,
                            vol:2.2,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Cama Queen Size (Base y colchón)",
                            quantity:0,
                            vol:1.6,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Cama Matrimonial (Base y colchón)",
                            quantity:0,
                            vol:1.7,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Cama Individual (Base y colchón)",
                            quantity:0,
                            vol:1.9,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Colchón King Size",
                            quantity:0,
                            vol:1.1,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Colchón Queen Size",
                            quantity:0,
                            vol:0.8,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Colchón Matrimonial",
                            quantity:0,
                            vol:0.7,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Colchón Individual",
                            quantity:0,
                            vol:0.4,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Base King Size",
                            quantity:0,
                            vol:1.8,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Base Queen Size",
                            quantity:0,
                            vol:1.4,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Base Matrimonial",
                            quantity:0,
                            vol:1.2,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Base Individual",
                            quantity:0,
                            vol:0.9,
                            fragile:false,
                            category:"Recámaras"
                        }
                    ],
                    rightElements:[
                        {
                            title:"Cabecera King Size",
                            quantity:0,
                            vol:0.4,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Cabecera Queen Size",
                            quantity:0,
                            vol:0.3,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Cabecera Matrimonial",
                            quantity:0,
                            vol:0.2,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Cabecera Individual",
                            quantity:0,
                            vol:0.1,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Cuna Desarmable",
                            quantity:0,
                            vol:0.5,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Cuna No Desarmable",
                            quantity:0,
                            vol:2,
                            fragile:false,
                            category:"Recámaras"
                        },
                        {
                            title:"Biombo",
                            quantity:0,
                            vol:0.1,
                            fragile:false,
                            category:"Recámaras"
                        }
                    ]
                },

                table:{
                    selected:false,
                    header:"MESAS y SILLAS",
                    vol:0,
                    leftElements:[
                        {
                            title:"Mesa de noche o Buro",
                            quantity:0,
                            fragile:false,
                            vol:0.2,
                            category:"Mesas y Sillas"
                            
                        },
                        {
                            title:"Mesa para florero",
                            quantity:0,
                            fragile:false,
                            vol:0.5,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Escritorio Chico",
                            quantity:0,
                            fragile:false,
                            vol:0.7,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Escritorio Mediano",
                            quantity:0,
                            fragile:false,
                            vol:1,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Escritorio Grande",
                            quantity:0,
                            fragile:false,
                            vol:1.3,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Mesa de Centro",
                            quantity:0,
                            fragile:false,
                            vol:0.3,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Mesa Auxiliar Baja",
                            quantity:0,
                            fragile:false,
                            vol:0.3,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Comedor 4 Personas",
                            quantity:0,
                            fragile:false,
                            vol:0.7,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Comedor 6 Personas",
                            quantity:0,
                            fragile:false,
                            vol:1.1,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Comedor 8 Personas",
                            quantity:0,
                            fragile:false,
                            vol:1.5,
                            category:"Mesas y Sillas"
                        }
                    ],
                    rightElements:[
                        {
                            title:"Comedor 10 Personas",
                            quantity:0,
                            fragile:false,
                            vol:2,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Comedor 12 Personas",
                            quantity:0,
                            fragile:false,
                            vol:2.4,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Comedor Grande de Piedra, Marmol, Otro",
                            quantity:0,
                            fragile:false,
                            vol:3,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Silla de Comedor",
                            quantity:0,
                            fragile:false,
                            vol:0.3,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Silla Auxiliar",
                            quantity:0,
                            fragile:false,
                            vol:0.2,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Silla de Escritorio",
                            quantity:0,
                            fragile:false,
                            vol:0.3,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Silla Reclinable",
                            quantity:0,
                            fragile:false,
                            vol:0.4,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Silla Mecedora",
                            quantity:0,
                            fragile:false,
                            vol:0.8,
                            category:"Mesas y Sillas"
                        },
                        {
                            title:"Banco Cantina",
                            quantity:0,
                            fragile:false,
                            vol:0.2,
                            category:"Mesas y Sillas"
                        }
                    ]
                    
                },

                drawers:{
                    selected:false,
                    vol:0,
                    header:"COMODAS Y CAJONERAS",
                    leftElements:[
                        {
                            title:"Cajonera",
                            quantity:0,
                            fragile:false,
                            vol:1,
                            category:"Cómodas y Cajoneras"
                        },
                        {
                            title:"Cajonera Doble",
                            quantity:0,
                            fragile:false,
                            vol:1.8,
                            category:"Cómodas y Cajoneras"
                        },
                        {
                            title:"Comoda",
                            quantity:0,
                            fragile:false,
                            vol:0.8,
                            category:"Cómodas y Cajoneras"
                        },
                        {
                            title:"Estantería",
                            quantity:0,
                            fragile:false,
                            vol:1,
                            category:"Cómodas y Cajoneras"
                        },
                        {
                            title:"Estantería tipo Escalera",
                            quantity:0,
                            fragile:false,
                            vol:0.5,
                            category:"Cómodas y Cajoneras"
                        },
                        {
                            title:"Tocador con Espejo",
                            quantity:0,
                            fragile:false,
                            vol:1,
                            category:"Cómodas y Cajoneras"
                        }
                    ],

                    rightElements:[
                        {
                            title:"Librero Grande",
                            quantity:0,
                            fragile:false,
                            vol:1.5,
                            category:"Cómodas y Cajoneras"
                        },
                        {
                            title:"Librero Mediano",
                            quantity:0,
                            fragile:false,
                            vol:0.7,
                            category:"Cómodas y Cajoneras"
                        },
                        {
                            title:"Librero Chico",
                            quantity:0,
                            fragile:false,
                            vol:0.5,
                            category:"Cómodas y Cajoneras"
                        },
                        {
                            title:"Mueble de TV",
                            quantity:0,
                            fragile:false,
                            vol:0.4,
                            category:"Cómodas y Cajoneras"
                        },
                        {
                            title:"Mueble de TV Gde",
                            quantity:0,
                            fragile:false,
                            vol:1.8,
                            category:"Cómodas y Cajoneras"
                        }
                    ]
                },

                kitchen:{
                    vol:0,
                    selected:false,
                    header:"COCINA",
                    leftElements:[
                        {
                            title:"Regrigerador 1 Pta y heladera",
                            vol:1.1,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Refrigerador Grande Doble",
                            vol:1.9,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Refrigerador Puerta de cristal",
                            vol:0.8,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Nevera Congelador",
                            vol:0.7,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Frigobar Grande",
                            vol:0.5,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Frigobar Chico",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Cava de Vinos chica",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Cava de Vinos Grande",
                            vol:0.5,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Estufa con Horno 4-6 Quemadores",
                            vol:0.6,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Horno eléctrico",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        }
                    ],

                    rightElements:[
                        {
                            title:"Microondas",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Vitrina Mediana",
                            vol:1.2,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Vitrina Grande",
                            vol:1.5,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Lava Platos",
                            vol:1.5,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Alacena",
                            vol:1.4,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Trinchador Mediano",
                            vol:0.7,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Trinchador Grande",
                            vol:1,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Bufetero Mediano",
                            vol:0.8,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        },
                        {
                            title:"Bufetero Grande",
                            vol:0.9,
                            quantity:0,
                            fragile:false,
                            category:"Cocina"
                        }
                    ]
                },

                miscellaneous:{
                    vol:0,
                    selected:false,
                    header:"VARIOS",
                    leftElements:[
                        {
                            title:"Alfombra (Tapete)",
                            vol:0.2,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Baúl",
                            vol:0.5,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Canasta",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Cuadro Chico",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Cuadro Grande",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Espejo 1 cuerpo",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Espejo Grande",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Estufa Eléctrica Chica",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Estufa Eléctrica Grande",
                            vol:0.8,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Bicicleta",
                            vol:0.7,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Bolsa de Golf",
                            vol:0.4,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Caminadora (Maquina de Ejercicio)",
                            vol:0.9,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Teclado Musical",
                            vol:0.4,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Guitarra o Bajo",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        }
                    ],

                    rightElements:[
                        {
                            title:"Batería Musical",
                            vol:1.2,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Piano Vertical sin Cola",
                            vol:1.9,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Piano de Cola",
                            vol:3.5,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Lámpara de Buro",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Lámpara de Pie",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Lámpara Colgante",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Pecera Chica",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Pecera Grande",
                            vol:0.4,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Jarrón Grande",
                            vol:0.2,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Periquera",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Futbolito",
                            vol:0.2,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Mesa de Billar",
                            vol:2.5,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Centro de Entretenimiento",
                            vol:1.7,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        },
                        {
                            title:"Carro Eléctrico Infantil",
                            vol:0.6,
                            quantity:0,
                            fragile:false,
                            category:"Varios"
                        }
                    ]
                },

                electronics:{
                    vol:0,
                    header:"ELECTRODOMÉSTICOS",
                    selected:false,
                    leftElements:[
                        {
                            title:"Lavadora",
                            vol:0.5,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"Secadora",
                            vol:0.4,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"Centro de Lavado 1 Pieza",
                            vol:0.5,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"Centro de Lavado y Secado, Vertical",
                            vol:1.1,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:`TV menor de 32"`,
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:`TV 34" a 55"`,
                            vol:0.2,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:`TV más grande de 55"`,
                            vol:0.4,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"Computadora de escritorio",
                            vol:0.5,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"Impresora chica",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        }
                    ],

                    rightElements:[
                        {
                            title:"Impresora mediana",
                            vol:0.2,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"Multifuncional",
                            vol:0.4,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"Equipo de sonido",
                            vol:0.7,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"Aspiradora",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"A/A Mini Split",
                            vol:1,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"A/A Ventana chico",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"A/A Ventana mediano",
                            vol:0.4,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"A/A Ventana Grande",
                            vol:0.6,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        },
                        {
                            title:"Ventilador",
                            vol:0.1,
                            quantity:0,
                            fragile:false,
                            category:"Electrodomésticos"
                        }
                    ]
                },

                garden:{
                    vol:0,
                    selected:false,
                    header:"TERRAZA Y JARDÍN",
                    leftElements:[
                        {
                            title:"Asador Mediano",
                            vol:0.2,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        },
                        {
                            title:"Asador Grande",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        },
                        {
                            title:"Banca Terraza",
                            vol:0.9,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        },
                        {
                            title:"Maceta Grande",
                            vol:0.6,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        },
                        {
                            title:"Maceta Mediana",
                            vol:0.4,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        },
                        {
                            title:"Macetón",
                            vol:0.2,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        }
                    ],

                    rightElements:[
                        {
                            title:"Mesa Centro Terraza",
                            vol:0.5,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        },
                        {
                            title:"Sillón 2 cuerpos Terraza",
                            vol:1.1,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        },
                        {
                            title:"Sillón 3 cuerpos Terraza",
                            vol:1.5,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        },
                        {
                            title:"Sofá Terraza",
                            vol:0.6,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        },
                        {
                            title:"Camastro",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        },
                        {
                            title:"Juego Infantil",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Terraza y Jardín"
                        }
                    ]
                },

                furniture:{
                    vol:0,
                    selected:false,
                    header:"SILLONES Y SOFAS",
                    leftElements:[
                        {
                            title:"Sillón",
                            vol:0.7,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        },
                        {
                            title:"Sofá 2 Cuerpos",
                            vol:0.9,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        },
                        {
                            title:"Sofá 3 Cuerpos",
                            vol:1.5,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        },
                        {
                            title:"Sofá 4 Cuerpos",
                            vol:2.1,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        },
                        {
                            title:"Sala - L",
                            vol:3.3,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        },
                        {
                            title:"Sala - 3, 2, 1",
                            vol:4,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        },
                        {
                            title:"Sala - Modular",
                            vol:3.3,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        }
                    ],

                    rightElements:[
                        {
                            title:"Sala 2 Piezas",
                            vol:2.6,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        },
                        {
                            title:"Futón",
                            vol:2,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        },
                        {
                            title:"Sofá Cama",
                            vol:0.6,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        },
                        {
                            title:"Love Seat",
                            vol:1.3,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        },
                        {
                            title:"Reposet",
                            vol:2,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        },
                        {
                            title:"Puff",
                            vol:0.6,
                            quantity:0,
                            fragile:false,
                            category:"Sillones y Sofás"
                        }
                    ]
                },

                boxes:{
                    vol:0,
                    header:"CAJAS, BOLSAS Y MALETAS",
                    selected:false,
                    leftElements:[
                        {
                            title:"Cajas de Cocina",
                            vol:0.2,
                            quantity:0,
                            fragile:false,
                            category:"Cajas, Bolsas y Maletas"
                        },
                        {
                            title:"Cajas de Adornos",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Cajas, Bolsas y Maletas"
                        },
                        {
                            title:"Cajas / Bolsas de Ropa",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Cajas, Bolsas y Maletas"
                        },
                        {
                            title:"Cajas Objetos Varios",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Cajas, Bolsas y Maletas"
                        },
                        {
                            title:"Contenedor Plástico",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Cajas, Bolsas y Maletas"
                        }
                    ],

                    rightElements:[
                        {
                            title:"Maletas Chicas",
                            vol:0.2,
                            quantity:0,
                            fragile:false,
                            category:"Cajas, Bolsas y Maletas"
                        },
                        {
                            title:"Maletas Grandes",
                            vol:0.4,
                            quantity:0,
                            fragile:false,
                            category:"Cajas, Bolsas y Maletas"
                        },
                        {
                            title:"Caja Fuerte Closet",
                            vol:0.2,
                            quantity:0,
                            fragile:false,
                            category:"Cajas, Bolsas y Maletas"
                        },
                        {
                            title:"Caja Fuerte Vertical",
                            vol:0.4,
                            quantity:0,
                            fragile:false,
                            category:"Cajas, Bolsas y Maletas"
                        },
                        {
                            title:"Caja Herramientas",
                            vol:0.3,
                            quantity:0,
                            fragile:false,
                            category:"Cajas, Bolsas y Maletas"
                        }
                    ]
                },

                vehicles:{
                    vol:0,
                    header:"VEHÍCULOS",
                    selected:false,
                    leftElements:[
                        {
                            title:"Carro Micro",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Carro Compacto",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Carro Sedan",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Carro Hatchback",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Carro Coupe",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Carro Babriolet",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Carro Roadster",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Carro Supercar",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Minivan",
                            active:false,
                            category:"Vehículos"
                        }
                    ],

                    middleElements:[
                        {
                            title:"Suv",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Pickup",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Motoneta",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Cuatrimoto",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Moto Deportiva",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Moto Clásica",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Moto Scooter",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Moto Gran Turismo",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Moto Trail",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Moto Enduro",
                            active:false,
                            category:"Vehículos"
                        }
                    ],

                    rightElements:[
                        {
                            title:"Moto Chopper",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Moto Calle",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Moto mini",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Moto Acuática",
                            active:false,
                            category:"Vehículos"
                        },
                        {
                            title:"Lancha (fuera de borda 6 mts)",
                            active:false,
                            category:"Vehículos"
                        }
                    ]
                }
            }
        }
    }

    componentDidMount(){
        this._mounted = true;

        this.getinventory();

        if(this._mounted){

            let position = {x:Number(this.props.lead.origin.x), y:Number(this.props.lead.origin.y)}
            let positionD = {x:Number(this.props.lead.destination.x), y:Number(this.props.lead.destination.y)}
            this.setState({
                position:position,
                positionD:positionD,
                newComment:this.props.lead.comment,
                details:this.props.lead.details
            })
        }

        window.document.body.style.overflow ="hidden"
    }

    componentWillUnmount(){
        this._mounted = false;

        window.document.body.style.overflow ="visible"
    }

    getinventory = () => {
        let count= 0;

        firebase.firestore().collection("items").where("category","==","Recámaras").orderBy("created","asc").get()
        .then(snap => {
            count++;
            let beds = [];

            snap.forEach(doc => {
                beds.push(doc.data());
            });

            let division = Math.ceil(beds.length/2);

            let leftElements = [];

            for(let i =0; i< division; i++){
                leftElements.push({
                    title:beds[i].item,
                    category:beds[i].category,
                    vol:Number(beds[i].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let rightElements = [];

            for(let i =0; i< (beds.length - division); i++){
                rightElements.push({
                    title:beds[i + division].item,
                    category:beds[i+division].category,
                    vol:Number(beds[i+division].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let base = Object.assign({}, this.state.inventory);
            base.bed.leftElements = leftElements;
            base.bed.rightElements = rightElements;

            

            if(this._mounted){
                this.setState({
                    inventory:base
                }, () => {
                    if(count === 10){
                        base.loaded = true;
                        this.loadLead();
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");
        })


        firebase.firestore().collection("items").where("category","==","Mesas y Sillas").orderBy("created","asc").get()
        .then(snap => {
            count++;
            let items = [];

            snap.forEach(doc => {
                items.push(doc.data());
            });

            let division = Math.ceil(items.length/2);

            let leftElements = [];

            for(let i =0; i< division; i++){
                leftElements.push({
                    title:items[i].item,
                    category:items[i].category,
                    vol:Number(items[i].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let rightElements = [];

            for(let i =0; i< (items.length - division); i++){
                rightElements.push({
                    title:items[i + division].item,
                    category:items[i+division].category,
                    vol:Number(items[i+division].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let base = Object.assign({}, this.state.inventory);
            base.table.leftElements = leftElements;
            base.table.rightElements = rightElements;

            

            if(this._mounted){
                this.setState({
                    inventory:base
                }, () => {
                    if(count === 10){
                        base.loaded = true;
                        this.loadLead();
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");
        })



        firebase.firestore().collection("items").where("category","==","Cómodas y Cajoneras").orderBy("created","asc").get()
        .then(snap => {
            count++;
            let items = [];

            snap.forEach(doc => {
                items.push(doc.data());
            });

            let division = Math.ceil(items.length/2);

            let leftElements = [];

            for(let i =0; i< division; i++){
                leftElements.push({
                    title:items[i].item,
                    category:items[i].category,
                    vol:Number(items[i].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let rightElements = [];

            for(let i =0; i< (items.length - division); i++){
                rightElements.push({
                    title:items[i + division].item,
                    category:items[i+division].category,
                    vol:Number(items[i+division].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let base = Object.assign({}, this.state.inventory);
            base.drawers.leftElements = leftElements;
            base.drawers.rightElements = rightElements;

            

            if(this._mounted){
                this.setState({
                    inventory:base
                }, () => {
                    if(count === 10){
                        base.loaded = true;
                        this.loadLead();
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");
        })


        firebase.firestore().collection("items").where("category","==","Cocina").orderBy("created","asc").get()
        .then(snap => {
            count++;
            let items = [];

            snap.forEach(doc => {
                items.push(doc.data());
            });

            let division = Math.ceil(items.length/2);

            let leftElements = [];

            for(let i =0; i< division; i++){
                leftElements.push({
                    title:items[i].item,
                    category:items[i].category,
                    vol:Number(items[i].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let rightElements = [];

            for(let i =0; i< (items.length - division); i++){
                rightElements.push({
                    title:items[i + division].item,
                    category:items[i+division].category,
                    vol:Number(items[i+division].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let base = Object.assign({}, this.state.inventory);
            base.kitchen.leftElements = leftElements;
            base.kitchen.rightElements = rightElements;

            

            if(this._mounted){
                this.setState({
                    inventory:base
                }, () => {
                    if(count === 10){
                        base.loaded = true;
                        this.loadLead();
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");
        })



        firebase.firestore().collection("items").where("category","==","Varios").orderBy("created","asc").get()
        .then(snap => {
            count++;
            let items = [];

            snap.forEach(doc => {
                items.push(doc.data());
            });

            let division = Math.ceil(items.length/2);

            let leftElements = [];

            for(let i =0; i< division; i++){
                leftElements.push({
                    title:items[i].item,
                    category:items[i].category,
                    vol:Number(items[i].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let rightElements = [];

            for(let i =0; i< (items.length - division); i++){
                rightElements.push({
                    title:items[i + division].item,
                    category:items[i+division].category,
                    vol:Number(items[i+division].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let base = Object.assign({}, this.state.inventory);
            base.miscellaneous.leftElements = leftElements;
            base.miscellaneous.rightElements = rightElements;

            

            if(this._mounted){
                this.setState({
                    inventory:base
                }, () => {
                    if(count === 10){
                        base.loaded = true;
                        this.loadLead();
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");
        })


        firebase.firestore().collection("items").where("category","==","Terraza y Jardín").orderBy("created","asc").get()
        .then(snap => {
            count++;
            let items = [];

            snap.forEach(doc => {
                items.push(doc.data());
            });

            let division = Math.ceil(items.length/2);

            let leftElements = [];

            for(let i =0; i< division; i++){
                leftElements.push({
                    title:items[i].item,
                    category:items[i].category,
                    vol:Number(items[i].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let rightElements = [];

            for(let i =0; i< (items.length - division); i++){
                rightElements.push({
                    title:items[i + division].item,
                    category:items[i+division].category,
                    vol:Number(items[i+division].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let base = Object.assign({}, this.state.inventory);
            base.garden.leftElements = leftElements;
            base.garden.rightElements = rightElements;

            

            if(this._mounted){
                this.setState({
                    inventory:base
                }, () => {
                    if(count === 10){
                        base.loaded = true;
                        this.loadLead();
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");
        })



        firebase.firestore().collection("items").where("category","==","Sillones y Sofás").orderBy("created","asc").get()
        .then(snap => {
            count++;
            let items = [];

            snap.forEach(doc => {
                items.push(doc.data());
            });

            let division = Math.ceil(items.length/2);

            let leftElements = [];

            for(let i =0; i< division; i++){
                leftElements.push({
                    title:items[i].item,
                    category:items[i].category,
                    vol:Number(items[i].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let rightElements = [];

            for(let i =0; i< (items.length - division); i++){
                rightElements.push({
                    title:items[i + division].item,
                    category:items[i+division].category,
                    vol:Number(items[i+division].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let base = Object.assign({}, this.state.inventory);
            base.furniture.leftElements = leftElements;
            base.furniture.rightElements = rightElements;

            

            if(this._mounted){
                this.setState({
                    inventory:base
                }, () => {
                    if(count === 10){
                        base.loaded = true;
                        this.loadLead();
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");
        })



        firebase.firestore().collection("items").where("category","==","Cajas, Bolsas y Maletas").orderBy("created","asc").get()
        .then(snap => {
            count++;
            let items = [];

            snap.forEach(doc => {
                items.push(doc.data());
            });

            let division = Math.ceil(items.length/2);

            let leftElements = [];

            for(let i =0; i< division; i++){
                leftElements.push({
                    title:items[i].item,
                    category:items[i].category,
                    vol:Number(items[i].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let rightElements = [];

            for(let i =0; i< (items.length - division); i++){
                rightElements.push({
                    title:items[i + division].item,
                    category:items[i+division].category,
                    vol:Number(items[i+division].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let base = Object.assign({}, this.state.inventory);
            base.boxes.leftElements = leftElements;
            base.boxes.rightElements = rightElements;

            

            if(this._mounted){
                this.setState({
                    inventory:base
                }, () => {
                    if(count === 10){
                        base.loaded = true;
                        this.loadLead();
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");
        })


        firebase.firestore().collection("items").where("category","==","Vehículos").orderBy("created","asc").get()
        .then(snap => {
            count++;
            let items = [];

            snap.forEach(doc => {
                items.push(doc.data());
            });

            let division = Math.ceil(items.length/3);

            let leftElements = [];

            for(let i =0; i< division; i++){
                leftElements.push({
                    title:items[i].item,
                    category:items[i].category,
                    active:false,
                    vol:items[i].vol
                })
            }

            let division2 = Math.ceil((items.length - division)/2);

            let rightElements = [];

            for(let i =0; i< division2; i++){
                rightElements.push({
                    title:items[i + division].item,
                    category:items[i + division].category,
                    active:false,
                    vol:items[i + division].vol
                })
            }


            let middleElements = [];

            for(let i =0; i< (items.length -(division + division2)); i++){
                middleElements.push({
                    title:items[i + division + division2].item,
                    category:items[i + division + division2].category,
                    active:false,
                    vol:items[i + division + division2].vol
                })
            }

            let base = Object.assign({}, this.state.inventory);
            base.vehicles.leftElements = leftElements;
            base.vehicles.rightElements = rightElements;
            base.vehicles.middleElements = middleElements;

            

            if(this._mounted){
                this.setState({
                    inventory:base
                }, () => {
                    if(count === 10){
                        base.loaded = true;
                        this.loadLead();
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");
        })



        firebase.firestore().collection("items").where("category","==","Electrodomésticos").orderBy("created","asc").get()
        .then(snap => {
            count++;
            let items = [];

            snap.forEach(doc => {
                items.push(doc.data());
            });

            let division = Math.ceil(items.length/2);

            let leftElements = [];

            for(let i =0; i< division; i++){
                leftElements.push({
                    title:items[i].item,
                    category:items[i].category,
                    vol:Number(items[i].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let rightElements = [];

            for(let i =0; i< (items.length - division); i++){
                rightElements.push({
                    title:items[i + division].item,
                    category:items[i+division].category,
                    vol:Number(items[i+division].vol),
                    fragile:false,
                    quantity:0
                })
            }

            let base = Object.assign({}, this.state.inventory);
            base.electronics.leftElements = leftElements;
            base.electronics.rightElements = rightElements;

            

            if(this._mounted){
                this.setState({
                    inventory:base
                }, () => {
                    if(count === 10){
                        base.loaded = true;
                        this.loadLead();
                    }
                })
            }
        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");
        })
    }

    updateLead=async () => {

        if(this._mounted){
            this.setState({
                feedback:"Actualizando Lead..."
            })
        }
        let vehicles = []

        let arrV = [this.state.inventory.vehicles.leftElements, this.state.inventory.vehicles.middleElements, this.state.inventory.vehicles.rightElements]

        for(let i=0; i< arrV.length; i++){
            for(let j =0; j<arrV[i].length; j++){
                if(arrV[i][j].active === true){
                    vehicles.push(arrV[i][j]);
                }
            }
        }

        let inventory = [];
        let arrElements = [
            this.state.inventory.bed,
            this.state.inventory.table,
            this.state.inventory.drawers, 
            this.state.inventory.kitchen,
            this.state.inventory.miscellaneous,
            this.state.inventory.electronics,
            this.state.inventory.garden,
            this.state.inventory.boxes,
            this.state.inventory.furniture,
        ]

        for(let i =0; i < arrElements.length; i++){
            for(let j =0; j < arrElements[i].leftElements.length; j++){
                if(arrElements[i].leftElements[j].quantity > 0){
                    inventory.push(arrElements[i].leftElements[j])
                }
            }

            for(let j =0; j< arrElements[i].rightElements.length; j++){
                if(arrElements[i].rightElements[j].quantity > 0){
                    inventory.push(arrElements[i].rightElements[j])
                }
            }
        }

        let images = []

        for(let i=0; i< this.state.images.length; i++){
            images.push({
                id:this.state.images[i]["id"],
                url:this.state.images[i]["url"]
            })
        }

        if(this.state.date >= new Date()){

            let batch = firebase.firestore().batch();

            batch.update(firebase.firestore().collection("leads").doc(this.props.lead.id),{
                inventory:inventory,
                images:images,
                vol:this.state.inventory.vol,
                vehicles:vehicles,
                updated:firebase.firestore.Timestamp.now(),
                deliveryDate:this.state.date,
                originAddress:this.state.positionChanged === false?this.props.lead.originAddress:this.state.address,

                buldingType:this.state.propertyTypeChanged === false?this.props.lead.buildingType:this.state.propertyType.home === true?"Casa":
                this.state.propertyType.department === true?`Departamento Piso ${this.state.departmentData.floor}, con elevador ${this.state.departmentData.elevator === true?"no operativo":"operativo"}`:
                this.state.propertyType.other === true?`${this.state.otherData.type}, Piso ${this.state.otherData.floor}`:"",

                distanceToEntrance:this.state.recolectionDistanceChanged == false?this.props.lead.distanceToEntrance:this.state.recolectionDistance.firstDistance === true?
                "A menos de 20 mts de la entrada":
                this.state.recolectionDistance.secondDistance === true?"A más de 50 mts y menos de 100 mts de la entrada":
                this.state.recolectionDistance.thirdDistance === true?"A más de 100 mts de la entrada":"",

                destinationAddress:this.state.positionDChanged === false?this.props.lead.destinationAddress:this.state.addressD,

                distanceToEntrance2:this.state.recolectionDistanceDChanged == false?this.props.lead.distanceToEntrance2:this.state.recolectionDistanceD.firstDistance === true?
                "A menos de 20 mts de la entrada":
                this.state.recolectionDistanceD.secondDistance === true?"A más de 50 mts y menos de 100 mts de la entrada":
                this.state.recolectionDistanceD.thirdDistance === true?"A más de 100 mts de la entrada":"",

                buildingType2:this.state.propertyTypeDChanged === false?this.props.lead.buildingType2:this.state.propertyTypeD.home === true?"Casa":
                this.state.propertyTypeD.department === true?`Departamento Piso ${this.state.departmentDataD.floor}, con elevador ${this.state.departmentDataD.elevator === true?"no operativo":"operativo"}`:
                this.state.propertyTypeD.other === true?`${this.state.otherDataD.type}, Piso ${this.state.otherDataD.floor}`:"",

                details:this.state.details,

                comment:this.state.newComment

            })

            let chats = await firebase.firestore().collection("chats").where("leadID","==",this.props.lead.id).get()

            if(chats.empty === false){
                chats.forEach(doc => {
                    let messageID = firebase.firestore().collection("chats").doc(doc.id).collection("messages").doc().id;

                    batch.set(firebase.firestore().collection("chats").doc(doc.id).collection("messages").doc(messageID), {
                        created:new Date(),
                        message:"SE HAN ACTUALIZADO LOS DETALLES DE LA SOLICITUD DE MUDANZA",
                        read:false,
                        readTime:null,
                        senderID:firebase.auth().currentUser.uid,
                        senderName:this.props.userData.name,
                        type:"system"
                    })
                })
            }

        batch.commit()
        .then(() => {
            this.props.addToast("Lead actualizado");
            if(this._mounted){
                this.setState({
                    feedback:"",
                    updated:true
                })
            }

            this.props.closeModal();
        })
        .catch(e => {
            console.log(e);
            this.props.addToast("Algo salió mal");

            if(this._mounted){
                this.setState({
                    feedback:""
                })
            }
        })
    }else {
        if(this._mounted){
            this.setState({
                feedback:""
            })
        }
        this.props.addToast("La fecha debe ser hoy o mayor que hoy")
    }
    }


    saveData = (target, data) => {
        
        if(this._mounted){
            let base = Object.assign({}, this.state.inventory);
            base[target].vol = data.vol;
            base[target].leftElements = data.leftElements;
            base[target].rightElements = data.rightElements;

            if(data.middleElements !== undefined){
                if(data.middleElements.length > 0){
                    base[target].middleElements = data.middleElements
                }
            }

            if(base[target].vol > 0){
                base[target].selected = true;
            }else {
                if(data.middleElements !== undefined){
                    if(data.middleElements.length > 0){
                        let flag = 0
                        let rows = [data.leftElements, data.middleElements, data.rightElements];

                        for(let i =0; i < rows.length; i++){
                           

                            for(let j = 0; j < rows[i].length;j++){
                                if(rows[i][j].active === true){
                                    flag = 1;
                                    break;
                                }
                            }

                            if(flag == 1){
                                break
                            }
                        }

                        if(flag === 1){
                            base[target].selected = true;
                        }else {
                            base[target].selected = false;
                        }
                    }else {
                        base[target].selected = false;
                    }
                }else {
                base[target].selected = false;
                }
            }
            
            this.setState({
                inventory:base
            }, () => {
                if(this._mounted){
                    let vol = 0;
                    vol += this.state.inventory.bed.vol;
                    vol += this.state.inventory.table.vol;
                    vol += this.state.inventory.drawers.vol;
                    vol += this.state.inventory.kitchen.vol;
                    vol += this.state.inventory.miscellaneous.vol;
                    vol += this.state.inventory.electronics.vol;
                    vol += this.state.inventory.garden.vol;
                    vol += this.state.inventory.furniture.vol;
                    vol += this.state.inventory.boxes.vol;
                    vol += this.state.inventory.vehicles.vol

                    let base = Object.assign({}, this.state.inventory);

                    let elements = [this.state.inventory.bed, 
                    this.state.inventory.table,
                    this.state.inventory.drawers,
                    this.state.inventory.kitchen,
                    this.state.inventory.miscellaneous,
                    this.state.inventory.electronics,
                    this.state.inventory.garden,
                    this.state.inventory.furniture,
                    this.state.inventory.boxes,
                    this.state.inventory.vehicles
                    ]

                    let valid = false;

                    for(let i=0; i < elements.length; i++){
                        if(elements[i].selected === true){
                            valid = true;
                            break;
                        }
                    }

                    if(valid === true){
                        base.valid = true;
                    }else {
                        base.valid = false;
                    }

                    base.vol = Math.round(vol *100)/100;
                    this.setState({
                        inventory:base
                    })
                }
            })
        }
    }


    loadLead = () => {
        
        if(this.props.lead.images !== undefined){
            if(this._mounted){
                this.setState({
                    images:this.props.lead.images
                })
            }
        }

        let inventory = this.props.lead.inventory;
                        let vehicles = this.props.lead.vehicles;

                    

                        let base = Object.assign({}, this.state.inventory);

                        base.vol = 0;
                        base.bed.vol = 0;
                        base.table.vol = 0;
                        base.drawers.vol = 0;
                        base.kitchen.vol = 0;
                        base.miscellaneous.vol = 0;
                        base.garden.vol = 0;
                        base.furniture.vol = 0;
                        base.boxes.vol = 0;
                        base.vehicles.vol = 0;
                        base.electronics.vol = 0;
                        for(let i=0; i < inventory.length; i++){
                            if(inventory[i].category === "Recámaras"){
                                
                                for(let j =0; j < this.state.inventory.bed.leftElements.length; j++){
                                    if(this.state.inventory.bed.leftElements[j].title === inventory[i].title){
                                      base.bed.leftElements[j] = inventory[i];
                                      base.bed.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      base.bed.selected = true;
                                      base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                    }
                                }

                                for(let j =0; j < this.state.inventory.bed.rightElements.length; j++){
                                    if(this.state.inventory.bed.rightElements[j].title === inventory[i].title){
                                        base.bed.rightElements[j] = inventory[i];
                                        base.bed.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                        base.bed.selected = true;
                                        base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      }
                                }
                            }


                            if(inventory[i].category === "Mesas y Sillas"){
                                
                                for(let j =0; j < this.state.inventory.table.leftElements.length; j++){
                                    if(this.state.inventory.table.leftElements[j].title === inventory[i].title){
                                      base.table.leftElements[j] = inventory[i];
                                      base.table.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      base.table.selected = true;
                                      base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                    }
                                }

                                for(let j =0; j < this.state.inventory.table.rightElements.length; j++){
                                    if(this.state.inventory.table.rightElements[j].title === inventory[i].title){
                                        base.table.rightElements[j] = inventory[i];
                                        base.table.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                        base.table.selected = true;
                                        base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      }
                                }
                            }

                            if(inventory[i].category === "Cómodas y Cajoneras"){
                                
                                for(let j =0; j < this.state.inventory.drawers.leftElements.length; j++){
                                    if(this.state.inventory.drawers.leftElements[j].title === inventory[i].title){
                                      base.drawers.leftElements[j] = inventory[i];
                                      base.drawers.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      base.drawers.selected = true;
                                      base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                    }
                                }

                                for(let j =0; j < this.state.inventory.drawers.rightElements.length; j++){
                                    if(this.state.inventory.drawers.rightElements[j].title === inventory[i].title){
                                        base.drawers.rightElements[j] = inventory[i];
                                        base.drawers.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                        base.drawers.selected = true;
                                        base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      }
                                }
                            }

                            if(inventory[i].category === "Cocina"){
                            
                                for(let j =0; j < this.state.inventory.kitchen.leftElements.length; j++){
                                    if(this.state.inventory.kitchen.leftElements[j].title === inventory[i].title){
                                      base.kitchen.leftElements[j] = inventory[i];
                                      base.kitchen.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      base.kitchen.selected = true;
                                      base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                    }
                                }

                                for(let j =0; j < this.state.inventory.kitchen.rightElements.length; j++){
                                    if(this.state.inventory.kitchen.rightElements[j].title === inventory[i].title){
                                        base.kitchen.rightElements[j] = inventory[i];
                                        base.kitchen.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                        base.kitchen.selected = true;
                                        base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      }
                                }
                            }

                            if(inventory[i].category === "Varios"){
                                
                                for(let j =0; j < this.state.inventory.miscellaneous.leftElements.length; j++){
                                    if(this.state.inventory.miscellaneous.leftElements[j].title === inventory[i].title){
                                      base.miscellaneous.leftElements[j] = inventory[i];
                                      base.miscellaneous.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      base.miscellaneous.selected = true;
                                      base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                    }
                                }

                                for(let j =0; j < this.state.inventory.miscellaneous.rightElements.length; j++){
                                    if(this.state.inventory.miscellaneous.rightElements[j].title === inventory[i].title){
                                        base.miscellaneous.rightElements[j] = inventory[i];
                                        base.miscellaneous.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                        base.miscellaneous.selected = true;
                                        base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      }
                                }
                            }

                            if(inventory[i].category === "Terraza y Jardín"){
                              
                                for(let j =0; j < this.state.inventory.garden.leftElements.length; j++){
                                    if(this.state.inventory.garden.leftElements[j].title === inventory[i].title){
                                      base.garden.leftElements[j] = inventory[i];
                                      base.garden.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      base.garden.selected = true;
                                      base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                    }
                                }

                                for(let j =0; j < this.state.inventory.garden.rightElements.length; j++){
                                    if(this.state.inventory.garden.rightElements[j].title === inventory[i].title){
                                        base.garden.rightElements[j] = inventory[i];
                                        base.garden.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                        base.garden.selected = true;
                                        base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      }
                                }
                            }

                            if(inventory[i].category === "Sillones y Sofás"){
                            
                                for(let j =0; j < this.state.inventory.furniture.leftElements.length; j++){
                                    if(this.state.inventory.furniture.leftElements[j].title === inventory[i].title){
                                      base.furniture.leftElements[j] = inventory[i];
                                      base.furniture.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      base.furniture.selected = true;
                                      base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                    }
                                }

                                for(let j =0; j < this.state.inventory.furniture.rightElements.length; j++){
                                    if(this.state.inventory.furniture.rightElements[j].title === inventory[i].title){
                                        base.furniture.rightElements[j] = inventory[i];
                                        base.furniture.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                        base.furniture.selected = true;
                                        base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      }
                                }
                            }

                            if(inventory[i].category === "Cajas, Bolsas y Maletas"){
                                
                                for(let j =0; j < this.state.inventory.boxes.leftElements.length; j++){
                                    if(this.state.inventory.boxes.leftElements[j].title === inventory[i].title){
                                      base.boxes.leftElements[j] = inventory[i];
                                      base.boxes.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      base.boxes.selected = true;
                                      base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                    }
                                }

                                for(let j =0; j < this.state.inventory.boxes.rightElements.length; j++){
                                    if(this.state.inventory.boxes.rightElements[j].title === inventory[i].title){
                                        base.boxes.rightElements[j] = inventory[i];
                                        base.boxes.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                        base.boxes.selected = true;
                                        base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      }
                                }
                            }


                            if(inventory[i].category === "Electrodomésticos"){
                               
                                for(let j =0; j < this.state.inventory.electronics.leftElements.length; j++){
                                    if(this.state.inventory.electronics.leftElements[j].title === inventory[i].title){
                                      base.electronics.leftElements[j] = inventory[i];
                                      base.electronics.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      base.electronics.selected = true;
                                      base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                    }
                                }

                                for(let j =0; j < this.state.inventory.electronics.rightElements.length; j++){
                                    if(this.state.inventory.electronics.rightElements[j].title === inventory[i].title){
                                        base.electronics.rightElements[j] = inventory[i];
                                        base.electronics.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                        base.electronics.selected = true;
                                        base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      }
                                }
                            }
                        }

                        if(vehicles !== undefined){
                            for(let i=0; i < vehicles.length; i++){
                                
                                for(let j=0; j< this.state.inventory.vehicles.leftElements.length; j++){
                                    if(this.state.inventory.vehicles.leftElements[j].title === vehicles[i].title){
                                        base.vehicles.leftElements[j] = vehicles[i];
                                       
                                        base.vehicles.selected = true;

                                        base.vehicles.vol += Number(vehicles[i].vol);
                                        base.vol += Number(vehicles[i].vol);
                                    }
                                }

                                for(let j=0; j< this.state.inventory.vehicles.middleElements.length; j++){
                                    if(this.state.inventory.vehicles.middleElements[j].title === vehicles[i].title){
                                        base.vehicles.middleElements[j] = vehicles[i];
                                       // base.vehicles.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                        base.vehicles.selected = true;
                                       // base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                       base.vehicles.vol += Number(vehicles[i].vol);
                                        base.vol += Number(vehicles[i].vol);
                                    }
                                }

                                for(let j=0; j< this.state.inventory.vehicles.rightElements.length; j++){
                                    if(this.state.inventory.vehicles.rightElements[j].title === vehicles[i].title){
                                        base.vehicles.rightElements[j] = vehicles[i];
                                     //   base.vehicles.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                        base.vehicles.selected = true;
                                      //  base.vol += Number(inventory[i].vol)*Number(inventory[i].quantity);
                                      base.vehicles.vol += Number(vehicles[i].vol);
                                        base.vol += Number(vehicles[i].vol);
                                    }
                                }
                            }
                        }
                      
                        base.vol = Math.round(base.vol *100)/100;
                        base.bed.vol = Math.round(base.bed.vol *100)/100;
                        base.table.vol = Math.round(base.table.vol *100)/100;
                        base.drawers.vol = Math.round(base.drawers.vol *100)/100;
                        base.kitchen.vol = Math.round(base.kitchen.vol *100)/100;
                        base.miscellaneous.vol = Math.round(base.miscellaneous.vol *100)/100;
                        base.garden.vol = Math.round(base.garden.vol *100)/100;
                        base.furniture.vol = Math.round(base.furniture.vol *100)/100;
                        base.boxes.vol = Math.round(base.boxes.vol *100)/100;
                        base.vehicles.vol = Math.round(base.vehicles.vol *100)/100;
                        base.electronics.vol = Math.round(base.electronics.vol *100)/100;
                        if(this._mounted){
                            this.setState({
                                inventory:base,
                                date:this.props.lead.deliveryDate.toDate()
                            })
                        }
    }


    render(){
        return <div className="modal-home-wrapper" style={{
            zIndex:20
        }}>
            
        {this.state.feedback !== ""?<LoadingFeedback feedback={this.state.feedback}/>:null}
        {this.state.modal === true?<ModalInventory
        onlyToggle={this.state.onlyToggle}
        target={this.state.target}
        toggleState={(side, index) => {
            if(side === "left"){
                let leftElements = [...this.state.leftElements];
                leftElements[index].active = !leftElements[index].active;

                let vol= this.state.vol;

                if(leftElements[index].active === true){
                    vol += leftElements[index].vol;
                }else {
                    vol -= leftElements[index].vol;
                }

                vol = Math.round(vol * 100)/100

                if(this._mounted){
                    this.setState({
                        leftElements:leftElements,
                        vol:vol
                    })
                }
            }

            if(side === "middle"){
                let middleElements = [...this.state.middleElements];
                middleElements[index].active = !middleElements[index].active;

                let vol = this.state.vol;

                if(middleElements[index].active === true){
                    vol += middleElements[index].vol;
                }else {
                    vol -= middleElements[index].vol;
                }

                vol = Math.round(vol * 100)/100

                if(this._mounted){
                    this.setState({
                        middleElements:middleElements,
                        vol:vol
                    })
                }
            }

            if(side === "right"){
                let rightElements = [...this.state.rightElements];
                rightElements[index].active = !rightElements[index].active;

                let vol = this.state.vol;

                if(rightElements[index].active === true){
                   vol += rightElements[index].vol;
                }else {
                    vol -= rightElements[index].vol;
                }

                vol = Math.round(vol * 100)/100

                if(this._mounted){
                    this.setState({
                        rightElements:rightElements,
                        vol:vol
                    })
                }
            }
        }}
        saveData={target => {
            this.saveData(target, {
                vol:this.state.vol,
                rightElements:this.state.rightElements,
                leftElements:this.state.leftElements,
                middleElements:this.state.middleElements
            });

            if(this._mounted){
                this.setState({
                    modal:false
                })
            }
        }}
        setFragile={(side, index) => {
            if(side === "left"){
                let leftElements= [...this.state.leftElements];
                leftElements[index].fragile = !leftElements[index].fragile;
                if(this._mounted){
                    this.setState({
                        leftElements:leftElements
                    })
                }
            }else if(side === "right"){
                let rightElements= [...this.state.rightElements];
                rightElements[index].fragile = !rightElements[index].fragile;

                if(this._mounted){
                    this.setState({
                        rightElements:rightElements
                    })
                }
            }
        }}
        updateQuantity={(side, type, value, index) => {
            if(side === "left"){
                if(type === "add"){
                    let leftElements = [...this.state.leftElements]
                    leftElements[index].quantity += value;

                    let vol = this.state.vol;
                    vol += leftElements[index].vol;

                    vol = Math.round(vol *100)/100
                    if(this._mounted){
                        this.setState({
                            leftElements:leftElements,
                            vol:vol
                        })
                    }
                }else if(type === "remove"){
                    let leftElements = [...this.state.leftElements]
                   

                    let vol = this.state.vol

                    if(leftElements[index].quantity - value < 0){
                        leftElements[index].quantity = 0
                    }else {
                        if(leftElements[index].quantity - value >= 0){
                            vol -= leftElements[index].vol
                        }

                        leftElements[index].quantity -= value;
                    }

                    vol = Math.round(vol *100)/100

                    
                    if(this._mounted){
                        this.setState({
                            leftElements:leftElements,
                            vol:vol
                        })
                    }
                }
            }else if(side === "right"){
                if(type === "add"){
                    let rightElements = [...this.state.rightElements];
                    rightElements[index].quantity += value;

                    let vol = this.state.vol;

                    vol += rightElements[index].vol

                    vol = Math.round(vol *100)/100

                    if(this._mounted){
                        this.setState({
                            rightElements:rightElements,
                            vol:vol
                        })
                    }
                }else if(type === "remove"){
                    let rightElements = [...this.state.rightElements];
                    

                    let vol = this.state.vol;

                    if(rightElements[index].quantity - value < 0){
                        rightElements[index].quantity = 0;
                    }else {
                        if(rightElements[index].quantity- value >= 0){
                            vol -= rightElements[index].vol
                        }
                        rightElements[index].quantity -= value;
                    }

                    vol = Math.round(vol *100)/100

                    
                    if(this._mounted){
                        this.setState({
                            rightElements:rightElements,
                            vol:vol
                        })
                    }
                }
            }
        }}
        header={this.state.header}
        vol={this.state.vol}
        leftElements={this.state.leftElements}
        rightElements={this.state.rightElements}
        middleElements={this.state.middleElements}
        />:null}

        <button className="btn btn-close-modal " style={{
            zIndex:9999999
        }} onClick={async() => {
            if(this._mounted){
                this.setState({
                    feedback:"Espere un momento..."
                })
            }

            if(this.state.updated === false){

            for(let i =0; i< this.state.images.length; i++){
                if(this.state.images[i].preloaded === undefined){
                if(this.state.images[i].progress < 100){
                   
                    await this.state.images[i].task.cancel()
                    
                }

                if(this.state.images[i].progress === 100){
                   
                    let storageRef = firebase.storage().ref();

                    try {
             await storageRef.child(`leads/${this.state.images[i].id}`).delete()
              
                    }catch(e){
                  console.log(e);

                  if(this.state.images[i].code_ === "storage/object-not-found"){
                    let base = [...this.state.images];
                    base.splice(i,1);
                      this.props.addToast("Imagen Eliminada!");
                   
                  }
                  this.props.addToast("Algo salió mal");

                  
            }
             
                } 

            }
            }

        }

            if(this._mounted){
                this.setState({
                    feedback:""
                })
            }
            this.props.closeModal();
        }}>
            <i className="material-icons align-middle">clear</i>
        </button>
        <div className="modal-home lead-data">
            {this.state.inventory.loaded === false?<div className="flex-center mt-2"><div className="spinner-border"></div></div>:<>
        {this.state.screen === 0?<>
             <div className="flex-left">
                 <button className="btn btn-custom-1" onClick={this.updateLead}>
                     <i className="material-icons align-middle mr-2">loop</i> <span>ACTUALIZAR</span>
                 </button>
             </div>
            <div className="field-element mt-3 pl-0 pr-0">
                <p className="header">Nombre de Cliente</p>
                {this.props.lead !== null?<p className="value mt-1">{this.props.lead.clientName}</p>:<div className="spinner-border"></div>}
            </div>

            <div className="field-element pl-0 pr-0">
                <p className="header">Email</p>
               {this.props.lead !== null?<p className="value mt-1">{this.props.lead.clientEmail}</p>:<div className="spinner-border"></div>}
            </div>

            <div className="field-element pl-0 pr-0">
                <p className="header">Celular</p>
                {this.props.lead !== null?<p className="value mt-1">{this.props.lead.clientPhone !== undefined?this.props.lead.clientPhone:""}</p>:<div className="spinner-border"></div>}
            </div>

            <div className="field-element pl-0 pr-0">

                <div className="flex-between">

                <div>
                <p className="header">Origen</p>
                {this.props.lead !== null?<p className="value mt-1">{this.state.positionChanged === false?this.props.lead.originAddress:this.state.address}</p>:<div className="spinner-border"></div>}

                <p className="header mt-2">Tipo de inmueble</p>
                {this.props.lead !== null?<p className="value mt-1">{this.state.propertyTypeChanged === false?this.props.lead.buildingType:this.state.propertyType.home === true?"Casa":
                this.state.propertyType.department === true?`Departamento Piso ${this.state.departmentData.floor}, con elevador ${this.state.departmentData.elevator === true?"no operativo":"operativo"}`:
                this.state.propertyType.other === true?`${this.state.otherData.type}, Piso ${this.state.otherData.floor}`:""}</p>:<div className="spinner-border"></div>}

                <p className="header mt-2">Distancia de estacionamiento</p>
                {this.props.lead !== null?<p className="value mt-1">{this.state.recolectionDistanceChanged == false?this.props.lead.distanceToEntrance:this.state.recolectionDistance.firstDistance === true?
                "A menos de 20 mts de la entrada":
                this.state.recolectionDistance.secondDistance === true?"A más de 50 mts y menos de 100 mts de la entrada":
                this.state.recolectionDistance.thirdDistance === true?"A más de 100 mts de la entrada":""}</p>:<div className="spinner-border"></div>}
                </div>

                <div>
                <button className="btn btn-edit" onClick={() => {
                   
                   if(this._mounted){
                      this.setState({
              
                       screen:3
                      })
                   }
                }}>
                    <i className="material-icons align-middle">create</i>
                 </button>
                </div>

                </div>
            </div>

            <div className="field-element pl-0 pr-0">

                <div className="flex-between">

                <div>
                <p className="header">Destino</p>
                {this.props.lead !== null?<p className="value mt-1">{this.state.positionDChanged === false?this.props.lead.destinationAddress:this.state.addressD}</p>:<div className="spinner-border"></div>}

                <p className="header mt-2">Tipo de inmueble</p>
                {this.props.lead !== null?<p className="value mt-1">{this.state.propertyTypeDChanged === false?this.props.lead.buildingType2:this.state.propertyTypeD.home === true?"Casa":
                this.state.propertyTypeD.department === true?`Departamento Piso ${this.state.departmentDataD.floor}, con elevador ${this.state.departmentDataD.elevator === true?"no operativo":"operativo"}`:
                this.state.propertyTypeD.other === true?`${this.state.otherDataD.type}, Piso ${this.state.otherDataD.floor}`:""}</p>:<div className="spinner-border"></div>}

                <p className="header mt-2">Distancia de estacionamiento</p>
                {this.props.lead !== null?<p className="value mt-1">{this.state.recolectionDistanceDChanged == false?this.props.lead.distanceToEntrance2:this.state.recolectionDistanceD.firstDistance === true?
                "A menos de 20 mts de la entrada":
                this.state.recolectionDistanceD.secondDistance === true?"A más de 50 mts y menos de 100 mts de la entrada":
                this.state.recolectionDistanceD.thirdDistance === true?"A más de 100 mts de la entrada":""}</p>:<div className="spinner-border"></div>}
                </div>

                <div>
                <button className="btn btn-edit" onClick={() => {
                   
                   if(this._mounted){
                      this.setState({
              
                       screen:4
                      })
                   }
                }}>
                    <i className="material-icons align-middle">create</i>
                 </button>
                </div>

                </div>
            </div>

            <div className="field-element pl-0 pr-0">

                <div className="flex-between">
                
                <div>
                <p className="header">Servicio de preferencia</p>
                {this.state.editDetails === false?this.state.detailsChanged === false?this.props.lead !== null?<p className="value mt-1">{this.props.lead !== null?(() => {
                    let result = '';

                    for(let i=0; i < this.props.lead.details.length; i++){
                    if(this.props.lead.details[i].selected === true){

                        result += this.props.lead.details[i].title + ", "
                    }
                    }

                    return result
                })():""}</p>:<div className="spinner-border"></div>:null:null}

{this.state.editDetails === false?this.state.detailsChanged === true?this.props.lead !== null?<p className="value mt-1">{this.props.lead !== null?(() => {
                    let result = '';

                    for(let i=0; i < this.state.details.length; i++){
                    if(this.state.details[i].selected === true){

                        result += this.state.details[i].title + ", "
                    }
                    }

                    return result
                })():""}</p>:<div className="spinner-border"></div>:null:null}

                {this.state.editDetails === true?<div className="fade-in">

<div className="custom-control custom-checkbox" >
<input type="checkbox" className="custom-control-input" checked={this.state.details[0].selected}  name="example1"/>

<label onClick={() => {
 let base = [...this.state.details];
 base[0].selected = !base[0].selected;

 if(this._mounted){
     this.setState({
         details:base,
         detailsChanged:true
     })
 }
}} className="custom-control-label flex-left pt-1"><span>Mudanza Exclusiva</span></label>

</div>

<div className="custom-control custom-checkbox mt-2" >
<input type="checkbox" className="custom-control-input" checked={this.state.details[1].selected}  name="example1"/>

<label onClick={() => {
 
 let base = [...this.state.details];
 base[1].selected = !base[1].selected;

 if(this._mounted){
     this.setState({
         details:base,
         detailsChanged:true
     })
 }
}} className="custom-control-label flex-left pt-1" ><span>Mudanza Exclusiva Todo Incluido</span></label>

</div>

<div className="custom-control custom-checkbox mt-2" >
<input type="checkbox" className="custom-control-input" checked={this.state.details[2].selected}  name="example1"/>

<label onClick={() => {
 let base = [...this.state.details];
 base[2].selected = !base[2].selected;

 if(this._mounted){
     this.setState({
         details:base,
         detailsChanged:true
     })
 }
}} className="custom-control-label flex-left pt-1"><span>Mudanza Compartida</span></label>

</div>
                </div>:null}
                </div>

                <div>
                {this.state.editDetails === false?<button className="btn btn-edit" onClick={() => {
                   
                   if(this._mounted){
                      this.setState({
              
                        editDetails:true
                      })
                   }
                }}>
                    <i className="material-icons align-middle">create</i>
                 </button>:null}

                 {this.state.editDetails === true?<button className="btn btn-edit" onClick={() => {
                     if(this._mounted){
                         this.setState({
                             editDetails:false
                         })
                     }
                 }}>
                     <i className="material-icons align-middle">clear</i>
                 </button>:null}
                </div>

                </div>
            </div>

            <div className="field-element pl-0 pr-0">
                <div className="flex-between">
                <div>
                <p className="header">Fecha ideal de servicio</p>
                {this.props.lead !== null?<p className="value mt-1">{this.props.lead.deliveryDate.toDate().getDate() + "-"+ (this.props.lead.deliveryDate.toDate().getMonth()+1) + "-" +  this.props.lead.deliveryDate.toDate().getFullYear()}</p>:<div className="spinner-border"></div>}
                </div>


                <button className="btn btn-edit" onClick={() => {
                    if(this._mounted){
                        this.setState({
                            editDate:!this.state.editDate
                        })
                    }
                }}>
                    <i className="material-icons align-middle">create</i>
                </button>
                </div>

                {this.state.editDate === true?<div className="flex-column-left mt-2">
                <DatePicker
            todayButtonText="Hoy"
            value={this.state.date}
           defaultValue={this.state.date}
            onChange={(newDate) => {
                if(this._mounted){
                    this.setState({
                        date:newDate
                    })
                }
               }}
          
        />

       
                </div>:null}
            </div>

            <div className="field-element pl-0 pr-0">

                <div className="flex-between">
                <div>
                <p className="header">Inventario</p>
       
                {this.props.lead !== null?this.props.lead.inventory?(() => {
                    let vehicles = []

                    let arrV = [this.state.inventory.vehicles.leftElements, this.state.inventory.vehicles.middleElements, this.state.inventory.vehicles.rightElements]
            
                    for(let i=0; i< arrV.length; i++){
                        for(let j =0; j<arrV[i].length; j++){
                            if(arrV[i][j].active === true){
                                vehicles.push(arrV[i][j]);
                            }
                        }
                    }
            
                    let inventory = [];
                    let arrElements = [
                        this.state.inventory.bed,
                        this.state.inventory.table,
                        this.state.inventory.drawers, 
                        this.state.inventory.kitchen,
                        this.state.inventory.miscellaneous,
                        this.state.inventory.electronics,
                        this.state.inventory.garden,
                        this.state.inventory.boxes,
                        this.state.inventory.furniture,
                    ]
            
                    for(let i =0; i < arrElements.length; i++){
                        for(let j =0; j < arrElements[i].leftElements.length; j++){
                            if(arrElements[i].leftElements[j].quantity > 0){
                                inventory.push(arrElements[i].leftElements[j])
                            }
                        }
            
                        for(let j =0; j< arrElements[i].rightElements.length; j++){
                            if(arrElements[i].rightElements[j].quantity > 0){
                                inventory.push(arrElements[i].rightElements[j])
                            }
                        }
                    }
                    inventory = inventory.concat(vehicles);

                    return inventory
                })().map((e,i) => {
                    return <p className="value mt-1" key={i}>{e.quantity} {e.title}</p>
                }):null:<div className="spinner-border"></div>}
                </div>

                <button className="btn btn-edit" onClick={() => {
                   
                    if(this._mounted){
                        this.setState({
                      
                            screen:1
                        })
                    }
                }}>
                    <i className="material-icons align-middle">create</i>
                </button>
                </div>
            </div>


            <div className="field-element pl-0 pr-0">
                <div className="flex-between">
                    <div>
                       <p className="header">Volumen</p>
                       {this.props.lead !== null?<p className="value mt-1">{this.state.inventory.vol} m3</p>:null}
                    </div>

                    <div>
                       <button className="btn btn-edit" onClick={() => {
                   
                          if(this._mounted){
                             this.setState({
                     
                              screen:1
                             })
                          }
                       }}>
                           <i className="material-icons align-middle">create</i>
                        </button>
                    </div>
                </div>
              
            </div>

            <div className="field-element pl-0 pr-0">
                <div className="flex-between">
                    <div>
                       <p className="header">Observaciones</p>

                       {this.state.commentEdit === false?this.props.lead !== null?<p className="value mt-1">{this.state.commentChanged === false?this.props.lead.comment:this.state.newComment}</p>:null:null}

                       {this.state.commentEdit === true?<div className="form-group mb-0 mt-1"><textarea className="form-control" value={this.state.newComment} onChange={e => {
                           if(this._mounted){
                               this.setState({
                                   newComment:e.currentTarget.value,
                                   commentChanged:true
                               })
                           }
                       }}>

                       </textarea></div>:null}
                    </div>

                    <div>
                    {this.state.commentEdit === false?<button className="btn btn-edit" onClick={() => {
                   
                   if(this._mounted){
                      this.setState({
              
                       commentEdit:true
                      })
                   }
                }}>
                    <i className="material-icons align-middle">create</i>
                 </button>:null}

                 {this.state.commentEdit === true?<button className="btn btn-edit" onClick={() => {
                   
                   if(this._mounted){
                      this.setState({
              
                       commentEdit:false
                      })
                   }
                }}>
                     <i className="material-icons align-middle">clear</i>
                 </button>:null}
                   
                    </div>
                </div>
                
            </div>
            
            <div className="field-element pl-0 pr-0">

                <div className="flex-between">
                <div>
                <p className="header">Imágenes</p>
                {this.props.lead !== null?this.props.lead.images?this.state.images.length > this.props.lead.images.length?this.state.images.map((e,i) => {
                    return <a className="link a-value mt-1" target="_blank" href={e.url} key={i}>img_{i}</a>
                }):this.props.lead.images.map((e,i) => {
                    return <a className="link a-value mt-1" target="_blank" href={e.url} key={i}>img_{i}</a>
                }):null:<div className="spinner-border"></div>}
                </div>

                <button className="btn btn-edit" onClick={() => {
                    if(this.props.lead.images !== undefined){
                        let images = this.props.lead.images;

                        for(let i =0; i < images.length; i++){
                            images[i].ready = true;
                            images[i].progress = 100;
                            images[i].preloaded = true;
                        }
                        if(this._mounted){
                            this.setState({
                                screen:2,
                                images:images
                            })
                        }
                    }else {
                        if(this._mounted){
                            this.setState({
                                screen:2
                            })
                        }
                    }
                    
                }}>
                    <i className="material-icons align-middle">create</i>
                </button>
                </div>
            </div>
            </>:null}



            {this.state.screen === 1?
        <div>
            <button className="btn btn-back-left" onClick={() => {
                if(this._mounted){
                    this.setState({
                        screen:0
                    })
                }
            }}>
                <i className="material-icons align-middle">arrow_back</i>
            </button>
            <div className="inventory-elements flex-center mt-4">

<div className="inventory-element m-3">
<div style={{
backgroundImage:`url(${bed})`,
backgroundSize:"contain",
backgroundPosition:"center",
backgroundRepeat:"no-repeat"
}}  onClick={() => {
if(this._mounted){
    this.setState({
        header:this.state.inventory.bed.header,
        vol:this.state.inventory.bed.vol,
        leftElements:this.state.inventory.bed.leftElements,
        rightElements:this.state.inventory.bed.rightElements,
        target:"bed",
        onlyToggle:false
    }, () => {
        if(this._mounted){
            this.setState({
                modal:true
            })
        }
    })
}
}}>
{this.state.inventory.bed.selected?<button className="btn-selected">
    <i className="material-icons align-middle">done</i>
</button>:null}
</div>

<p>Recámaras</p>
</div>

<div className="inventory-element m-3">
<div style={{
backgroundImage:`url(${table})`,
backgroundSize:"contain",
backgroundPosition:"center",
backgroundRepeat:"no-repeat"
}} onClick={() => {
if(this._mounted){
 this.setState({
     header:this.state.inventory.table.header,
     vol:this.state.inventory.table.vol,
     leftElements:this.state.inventory.table.leftElements,
     rightElements:this.state.inventory.table.rightElements,
     target:"table",
     onlyToggle:false
 }, () => {
     if(this._mounted){
         this.setState({
             modal:true
         })
     }
 })
}
}}>
{this.state.inventory.table.selected?<button className="btn-selected">
    <i className="material-icons align-middle">done</i>
</button>:null}
</div>

<p>Mesas y Sillas</p>
</div>

<div className="inventory-element m-3">
<div style={{
backgroundImage:`url(${cajonera})`,
backgroundSize:"contain",
backgroundPosition:"center",
backgroundRepeat:"no-repeat"
}} onClick={() => {
if(this._mounted){
 this.setState({
     header:this.state.inventory.drawers.header,
     vol:this.state.inventory.drawers.vol,
     leftElements:this.state.inventory.drawers.leftElements,
     rightElements:this.state.inventory.drawers.rightElements,
     target:"drawers",
     onlyToggle:false
 }, () => {
     if(this._mounted){
         this.setState({
             modal:true
         })
     }
 })
}
}}>
{this.state.inventory.drawers.selected?<button className="btn-selected">
    <i className="material-icons align-middle">done</i>
</button>:null}
</div>

<p>Cómodas y Cajoneras</p>
</div>


<div className="inventory-element m-3">
<div style={{
backgroundImage:`url(${estufa})`,
backgroundSize:"contain",
backgroundPosition:"center",
backgroundRepeat:"no-repeat"
}} onClick={() => {
if(this._mounted){
 this.setState({
     header:this.state.inventory.kitchen.header,
     vol:this.state.inventory.kitchen.vol,
     leftElements:this.state.inventory.kitchen.leftElements,
     rightElements:this.state.inventory.kitchen.rightElements,
     target:"kitchen",
     onlyToggle:false
 }, () => {
     if(this._mounted){
         this.setState({
             modal:true
         })
     }
 })
}
}}>
{this.state.inventory.kitchen.selected?<button className="btn-selected">
    <i className="material-icons align-middle">done</i>
</button>:null}
</div>

<p>Cocina</p>
</div>

<div className="inventory-element m-3">
<div style={{
backgroundImage:`url(${bici})`,
backgroundSize:"contain",
backgroundPosition:"center",
backgroundRepeat:"no-repeat"
}}  onClick={() => {
if(this._mounted){
 this.setState({
     header:this.state.inventory.miscellaneous.header,
     vol:this.state.inventory.miscellaneous.vol,
     leftElements:this.state.inventory.miscellaneous.leftElements,
     rightElements:this.state.inventory.miscellaneous.rightElements,
     target:"miscellaneous",
     onlyToggle:false
 }, () => {
     if(this._mounted){
         this.setState({
             modal:true
         })
     }
 })
}
}}>
{this.state.inventory.miscellaneous.selected?<button className="btn-selected">
    <i className="material-icons align-middle">done</i>
</button>:null}
</div>

<p>Varios</p>
</div>

</div>


<div className="inventory-elements flex-center mt-2">

<div className="inventory-element m-3">
<div style={{
backgroundImage:`url(${TV_ICON})`,
backgroundSize:"contain",
backgroundPosition:"center",
backgroundRepeat:"no-repeat"
}} onClick={() => {
if(this._mounted){
 this.setState({
     header:this.state.inventory.electronics.header,
     vol:this.state.inventory.electronics.vol,
     leftElements:this.state.inventory.electronics.leftElements,
     rightElements:this.state.inventory.electronics.rightElements,
     target:"electronics",
     onlyToggle:false
 }, () => {
     if(this._mounted){
         this.setState({
             modal:true
         })
     }
 })
}
}}>
{this.state.inventory.electronics.selected?<button className="btn-selected">
    <i className="material-icons align-middle">done</i>
</button>:null}
</div>

<p>Electrónicos</p>
</div>

<div className="inventory-element m-3">
<div style={{
backgroundImage:`url(${jardin})`,
backgroundSize:"contain",
backgroundPosition:"center",
backgroundRepeat:"no-repeat"
}} onClick={() => {
if(this._mounted){
 this.setState({
     header:this.state.inventory.garden.header,
     vol:this.state.inventory.garden.vol,
     leftElements:this.state.inventory.garden.leftElements,
     rightElements:this.state.inventory.garden.rightElements,
     target:"garden",
     onlyToggle:false
 }, () => {
     if(this._mounted){
         this.setState({
             modal:true
         })
     }
 })
}
}}>
{this.state.inventory.garden.selected?<button className="btn-selected">
    <i className="material-icons align-middle">done</i>
</button>:null}
</div>

<p>Terraza y Jardín</p>
</div>

<div className="inventory-element m-3">
<div style={{
backgroundImage:`url(${sillones})`,
backgroundSize:"contain",
backgroundPosition:"center",
backgroundRepeat:"no-repeat"
}} onClick={() => {
if(this._mounted){
 this.setState({
     header:this.state.inventory.furniture.header,
     vol:this.state.inventory.furniture.vol,
     leftElements:this.state.inventory.furniture.leftElements,
     rightElements:this.state.inventory.furniture.rightElements,
     target:"furniture",
     onlyToggle:false
 }, () => {
     if(this._mounted){
         this.setState({
             modal:true
         })
     }
 })
}
}}>
{this.state.inventory.furniture.selected?<button className="btn-selected">
    <i className="material-icons align-middle">done</i>
</button>:null}
</div>

<p>Sillones y Sofás</p>
</div>


<div className="inventory-element m-3">
<div style={{
backgroundImage:`url(${caja2})`,
backgroundSize:"contain",
backgroundPosition:"center",
backgroundRepeat:"no-repeat"
}} onClick={() => {
if(this._mounted){
 this.setState({
     header:this.state.inventory.boxes.header,
     vol:this.state.inventory.boxes.vol,
     leftElements:this.state.inventory.boxes.leftElements,
     rightElements:this.state.inventory.boxes.rightElements,
     target:"boxes",
     onlyToggle:false
 }, () => {
     if(this._mounted){
         this.setState({
             modal:true
         })
     }
 })
}
}}>
{this.state.inventory.boxes.selected?<button className="btn-selected">
    <i className="material-icons align-middle">done</i>
</button>:null}
</div>

<p>Cajas, Bolsas y maletas</p>
</div>

<div className="inventory-element m-3">
<div style={{
backgroundImage:`url(${car})`,
backgroundSize:"contain",
backgroundPosition:"center",
backgroundRepeat:"no-repeat"
}} onClick={() => {
if(this._mounted){
 this.setState({
     header:this.state.inventory.vehicles.header,
     vol:this.state.inventory.vehicles.vol,
     leftElements:this.state.inventory.vehicles.leftElements,
     rightElements:this.state.inventory.vehicles.rightElements,
     middleElements:this.state.inventory.vehicles.middleElements,
     target:"vehicles",
     onlyToggle:true
 }, () => {
     if(this._mounted){
         this.setState({
             modal:true
         })
     }
 })
}
}}>
{this.state.inventory.vehicles.selected?<button className="btn-selected">
    <i className="material-icons align-middle">done</i>
</button>:null}
</div>

<p>Vehículos</p>
</div>

</div>

<h3 className="text-center mt-4">Volumen Total: <b>{this.state.inventory.vol} m3</b></h3>
        </div>:null}


        {this.state.screen === 2?<div className={`fade-in mt-1`}>
        
        <button className=" btn btn-back-left" onClick={async() => {
            if(this._mounted){
                this.setState({
                    screen:0
                })
            }
            
        }}>
            <i className="material-icons align-middle">arrow_back</i>
        </button>
       

           <div className="flex-center"><h1 className="main-header text-center mt-5">Selecciona las imágenes desde el archivo de tu computadora...</h1></div>

           <div className="photo-elements mt-4 flex-center">
               <div className="photo-element-add m-2 flex-center">
               {!this.state.resetting && (<div className="custom-file">
                        <input type="file" className="custom-file-input" onChange={(element) => {
                          let file = element.target.files[0];

                          let storageRef = firebase.storage().ref();

                          let id = firebase.firestore().collection("leads").doc().id;

                          var uploadTask = storageRef.child(`leads/${id}`).put(file);

                          let base = [...this.state.images];

                          let index = base.length
                          base.push({
                              file:file,
                              task:uploadTask,
                              progress:0,
                              error:false,
                              id:id,
                              ready:false,
                              url:""
                          })

                          if(this._mounted){
                              this.setState({
                                  images:base,
                                  updated:false
                              }, () => {

                               this.state.images[index].task.on('state_changed', (snapshot) =>{
                                    // Observe state change events such as progress, pause, and resume
                                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                    if(this._mounted){
                                        let base = [...this.state.images]
                                        base[index].progress = (Math.round(progress)/100)*100;

                                        this.setState({
                                            images:base
                                        })
                                    }
                                    console.log('Upload is ' + progress + '% done');
                                    switch (snapshot.state) {
                                      case firebase.storage.TaskState.PAUSED: // or 'paused'
                                        console.log('Upload is paused');
                                        break;
                                      case firebase.storage.TaskState.RUNNING: // or 'running'
                                        console.log('Upload is running');
                                        break;
                                    }
                                  }, (error) => {
                                      console.log(error);
                                      this.props.addToast("Algo salió mal");
                                      if(this._mounted){
                                        let base = [...this.state.images]
                                        base[index].error = true;

                                        this.setState({
                                            images:base
                                        })
                                    }
                                  }, ()  =>{
                                    // Handle successful uploads on complete
                                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL)=> {
                                        if(this._mounted){
                                            let base = [...this.state.images]
                                            base[index].ready = true;
                                            base[index].url = downloadURL;

                                            this.setState({
                                                images:base,
                                                valid:true
                                            })
                                        }
                    
                                    })
                                    .catch(e => {
                                        console.log(e);
                                        if(this._mounted){
                                            let base = [...this.state.images]
                                            base[index].error = true;

                                            this.setState({
                                                images:base
                                            })
                                        }
                                        this.props.addToast("Algo salió mal")
                                    })
                                  });
                              })
                          }
                 
                          

                          this.setState({resetting:true}, () => {
    
                            this.setState({resetting:false});
                          });
                        }}/>
                        <label className="custom-file-label"></label>
                       </div>)}
               <i className="material-icons align-middle">camera_alt</i>
               </div>

               {this.state.images.map((e,i) => {
                   return <div className="photo-element m-2" key={i} style={{
                       backgroundImage:`url(${e.file !== undefined?URL.createObjectURL(e.file):e.url})`,
                       backgroundRepeat:"no-repeat",
                       backgroundPosition:"center",
                       backgroundSize:"contain"
                   }}>

<button className="btn btn-clear-img" onClick={() => {
                            if(e.progress < 100){
                                if(this._mounted){
                                    this.setState({
                                        feedback:"Eliminando Imagen..."
                                    })
                                }
                                e.task.cancel()
                                .then(() => {
                                    let base = [...this.state.images];
                                    base.splice(i,1);

                                    this.progress.addToast("Imagen Eliminada");
                                    if(this._mounted){
                                        this.setState({
                                            feedback:"",
                                            images:base
                                        })
                                    }
                                })
                                .catch(e => {
                                    this.props.addToast("Algo salió mal");
                                    if(this._mounted){
                                        this.setState({
                                            feedback:""
                                        })
                                    }
                                })
                            }

                            if(e.progress === 100){
                                if(this._mounted){
                                    this.setState({
                                        feedback:"Eliminando Imagen..."
                                    })
                                }
                                let storageRef = firebase.storage().ref();

                          storageRef.child(`leads/${e.id}`).delete()
                          .then(() => {
                            let base = [...this.state.images];
                            base.splice(i,1);
                              this.props.addToast("Imagen Eliminada!");
                            if(this._mounted){
                                this.setState({
                                    feedback:"",
                                    images:base
                                })
                            }
                          })
                          .catch(e => {
                              console.log(e);

                              if(e.code_ === "storage/object-not-found"){
                                let base = [...this.state.images];
                                base.splice(i,1);
                                  this.props.addToast("Imagen Eliminada!");
                                if(this._mounted){
                                    this.setState({
                                        feedback:"",
                                        images:base
                                    })
                                }
                              }
                              this.props.addToast("Algo salió mal");

                              if(this._mounted){
                                this.setState({
                                    feedback:""
                                })
                            }
                          })
                            }
                        }}>
                            <i className="material-icons align-middle">clear</i>
                        </button>
                       
                        {e.ready != true?<div className="progress">
                           <div className="progress-bar" style={{width:`${e.progress}%`}}></div>
                        </div>:null}
                   </div>
               })}
           </div>

          
    </div>:null}

       {this.state.screen === 3?<div className="fade-in sign-up">

                 {this.state.sequence === 0?<PositionEdit
                   placeholder="Origen (Ciudad)"
                   address={this.state.address}
                   position={this.state.position}
                   changeSequence={n => {
                       if(this._mounted){
                           this.setState({
                               sequence:n,
                               positionChanged:true
                           })
                       }
                   }}

                   changeSequenceOmit={n => {
                    if(this._mounted){
                        this.setState({
                            sequence:n
                        })
                    }
                }}
                   setAddress={string => {
                       if(this._mounted){
                           this.setState({
                               address:string
                           })
                       }
                    
                   }}
                   header={"¿En que LUGAR se realizará la Recolección?"}
                   setCoords={coors => {
                      console.log(coors);
                       if(this._mounted){
                           this.setState({
                               position:coors,
                               positionChanged:true
                           })
                       }
                   }}
                   back={() => {
                       if(this._mounted){
                           this.setState({
                               screen:0
                           })
                       }
                   }}
                   changeScreen={value => {
                       if(this._mounted){
                           this.setState({
                               screen:value,
                               sequence:0
                           })
                       }
                   }}
                   addToast={this.props.addToast}
                 />:null}

                 {this.state.sequence === 1?<PropertyTypeEdit
                 changeSequence={n => {
                    if(this._mounted){
                        this.setState({
                            sequence:n,
                            propertyTypeChanged:true
                        })
                    }
                }}
                changeSequenceOmit={n => {
                    if(this._mounted){
                        this.setState({
                            sequence:n,
                           // propertyTypeChanged:true
                        })
                    }
                }}
                header={<>¿En que <b>TIPO DE INMUEBLE</b> se realizará la <b>RECOLECCIÓN</b>?</>}
                floor2={this.state.otherData.floor}
                type={this.state.otherData.type}
                house={this.state.propertyType.home}
                department={this.state.propertyType.department}
                other={this.state.propertyType.other}
                valid={this.state.propertyType.valid}
                back={() => {
                    if(this._mounted){
                        this.setState({
                            sequence:0
                        })
                    }
                }}
                changeScreen={value => {
                    if(this._mounted){
                        this.setState({
                            screen:value,
                            sequence:0
                        })
                    }
                }}
                elevator={this.state.departmentData.elevator}
                floor={this.state.departmentData.floor}
                changeFloor={(value) => {
                    if(this._mounted){
                        let base =Object.assign({}, this.state.departmentData);
                        base.floor = value;

                        this.setState({
                            departmentData:base,
                          
                        })
                    }
                }}
                changeElevator={value => {
                    if(this._mounted){
                        let base = Object.assign({}, this.state.departmentData);
                        base.elevator = value;

                        this.setState({
                            departmentData:base,
                            
                        })
                    }
                }}
                setFloor2 = {value => {
                    if(this._mounted){
                        let base = Object.assign({}, this.state.otherData);
                        base.floor = value;

                        this.setState({
                            otherData:base,
                            
                        })
                    }
                }}
                setType={value => {
                    if(this._mounted){
                        let base = Object.assign({}, this.state.otherData);
                        base.type = value;

                        this.setState({
                            otherData:base,
                            
                        })
                    }
                }}
                addToast={this.props.addToast}
                handleSelection={(type) => {
                    if(type === 0){
                        if(this._mounted){
                            this.setState({
                                propertyType:{
                                    home:true,
                                    department:false,
                                    other:false,
                                    valid:true
                                },
                            
                            })
                        }
                    }
                    if(type === 1){
                        if(this._mounted){
                            this.setState({
                                propertyType:{
                                    home:false,
                                    department:true,
                                    other:false,
                                    valid:true
                                },
                            
                            })
                        }
                    }

                    if(type === 2){
                        if(this._mounted){
                            this.setState({
                                propertyType:{
                                    home:false,
                                    department:false,
                                    other:true,
                                    valid:true
                                },
                                
                            })
                        }
                    }
                }}
                />:null}

                {this.state.sequence === 2?<RecolectionDistanceEdit
                changeSequence={n => {
                    if(this._mounted){
                        this.setState({
                            sequence:n,
                            recolectionDistanceChanged:true
                        })
                    }
                }}
                changeSequenceOmit={n => {
                    if(this._mounted){
                        this.setState({
                            sequence:n,
                            //recolectionDistanceChanged:true
                        })
                    }
                }}
                 header={<>¿A que <b>DISTANCIA</b> se puede estacionar el camión en el lugar de <b>RECOLECCIÓN</b>?</>}
                 addToast={this.props.addToast}
                 firstDistance={this.state.recolectionDistance.firstDistance}
                 secondDistance={this.state.recolectionDistance.secondDistance}
                 thirdDistance={this.state.recolectionDistance.thirdDistance}
                 valid={this.state.recolectionDistance.valid}
                 handleSelection={value => {
                     if(value === 0){
                         if(this._mounted){
                             let base = Object.assign({}, this.state.recolectionDistance);
                             base.firstDistance = true;
                             base.secondDistance =false;
                             base.thirdDistance = false;
                             base.valid = true;
 
                             this.setState({
                                 recolectionDistance:base,
                                 
                             })
                         }
                     }
 
                     if(value === 1){
                         if(this._mounted){
                             let base = Object.assign({}, this.state.recolectionDistance);
                             base.firstDistance = false;
                             base.secondDistance =true;
                             base.thirdDistance = false;
                             base.valid = true;
 
                             this.setState({
                                 recolectionDistance:base,
                                
                             })
                         }
                     }
 
                     if(value === 2){
                         if(this._mounted){
                             let base = Object.assign({}, this.state.recolectionDistance);
                             base.firstDistance = false;
                             base.secondDistance =false;
                             base.thirdDistance = true;
                             base.valid = true;
 
                             this.setState({
                                 recolectionDistance:base,
                                
                             })
                         }
                     }
                 }}
                 back={()=> {
                     if(this._mounted){
                         this.setState({
                             sequence:1
                         })
                     }
                 }}
                 changeScreen={n => {
                     if(this._mounted){
                         this.setState({
                             screen:n,
                             recolectionDistanceChanged:true,
                             sequence:0
                         })
                     }
                 }}

                 changeScreenOmit={n => {
                    if(this._mounted){
                        this.setState({
                            screen:n,
                            sequence:0
                        })
                    }
                }}
                />:null}

                


       </div>:null}



       {this.state.screen === 4?<div className="fade-in sign-up">

{this.state.sequence === 0?<PositionEdit
  placeholder="Origen (Ciudad)"
  address={this.state.addressD}
  position={this.state.positionD}
  changeSequence={n => {
      if(this._mounted){
          this.setState({
              sequence:n,
              positionDChanged:true
          })
      }
  }}

  changeSequenceOmit={n => {
   if(this._mounted){
       this.setState({
           sequence:n
       })
   }
}}
  setAddress={string => {
      if(this._mounted){
          this.setState({
              addressD:string
          })
      }
   
  }}
  header={"¿En que LUGAR se realizará la Recolección?"}
  setCoords={coors => {
     console.log(coors);
      if(this._mounted){
          this.setState({
              positionD:coors,
              //positionChanged:true
          })
      }
  }}
  back={() => {
      if(this._mounted){
          this.setState({
              screen:0
          })
      }
  }}
  changeScreen={value => {
      if(this._mounted){
          this.setState({
              screen:value,
              sequence:0
          })
      }
  }}
  addToast={this.props.addToast}
/>:null}

{this.state.sequence === 1?<PropertyTypeEdit
changeSequence={n => {
   if(this._mounted){
       this.setState({
           sequence:n,
           propertyTypeDChanged:true
       })
   }
}}
changeSequenceOmit={n => {
   if(this._mounted){
       this.setState({
           sequence:n,
          // propertyTypeChanged:true
       })
   }
}}
header={<>¿En que <b>TIPO DE INMUEBLE</b> se realizará la <b>RECOLECCIÓN</b>?</>}
floor2={this.state.otherDataD.floor}
type={this.state.otherDataD.type}
house={this.state.propertyTypeD.home}
department={this.state.propertyTypeD.department}
other={this.state.propertyTypeD.other}
valid={this.state.propertyTypeD.valid}
back={() => {
   if(this._mounted){
       this.setState({
           sequence:0
       })
   }
}}
changeScreen={value => {
   if(this._mounted){
       this.setState({
           screen:value,
           sequence:0
       })
   }
}}
elevator={this.state.departmentDataD.elevator}
floor={this.state.departmentDataD.floor}
changeFloor={(value) => {
   if(this._mounted){
       let base =Object.assign({}, this.state.departmentDataD);
       base.floor = value;

       this.setState({
           departmentDataD:base,
         
       })
   }
}}
changeElevator={value => {
   if(this._mounted){
       let base = Object.assign({}, this.state.departmentDataD);
       base.elevator = value;

       this.setState({
           departmentDataD:base,
           
       })
   }
}}
setFloor2 = {value => {
   if(this._mounted){
       let base = Object.assign({}, this.state.otherDataD);
       base.floor = value;

       this.setState({
           otherDataD:base,
           
       })
   }
}}
setType={value => {
   if(this._mounted){
       let base = Object.assign({}, this.state.otherDataD);
       base.type = value;

       this.setState({
           otherDataD:base,
           
       })
   }
}}
addToast={this.props.addToast}
handleSelection={(type) => {
   if(type === 0){
       if(this._mounted){
           this.setState({
               propertyTypeD:{
                   home:true,
                   department:false,
                   other:false,
                   valid:true
               },
           
           })
       }
   }
   if(type === 1){
       if(this._mounted){
           this.setState({
               propertyTypeD:{
                   home:false,
                   department:true,
                   other:false,
                   valid:true
               },
           
           })
       }
   }

   if(type === 2){
       if(this._mounted){
           this.setState({
               propertyTypeD:{
                   home:false,
                   department:false,
                   other:true,
                   valid:true
               },
               
           })
       }
   }
}}
/>:null}

{this.state.sequence === 2?<RecolectionDistanceEdit
changeSequence={n => {
   if(this._mounted){
       this.setState({
           sequence:n,
           recolectionDistanceDChanged:true
       })
   }
}}
changeSequenceOmit={n => {
   if(this._mounted){
       this.setState({
           sequence:n,
           //recolectionDistanceChanged:true
       })
   }
}}
header={<>¿A que <b>DISTANCIA</b> se puede estacionar el camión en el lugar de <b>RECOLECCIÓN</b>?</>}
addToast={this.props.addToast}
firstDistance={this.state.recolectionDistanceD.firstDistance}
secondDistance={this.state.recolectionDistanceD.secondDistance}
thirdDistance={this.state.recolectionDistanceD.thirdDistance}
valid={this.state.recolectionDistanceD.valid}
handleSelection={value => {
    if(value === 0){
        if(this._mounted){
            let base = Object.assign({}, this.state.recolectionDistanceD);
            base.firstDistance = true;
            base.secondDistance =false;
            base.thirdDistance = false;
            base.valid = true;

            this.setState({
                recolectionDistanceD:base,
                
            })
        }
    }

    if(value === 1){
        if(this._mounted){
            let base = Object.assign({}, this.state.recolectionDistanceD);
            base.firstDistance = false;
            base.secondDistance =true;
            base.thirdDistance = false;
            base.valid = true;

            this.setState({
                recolectionDistanceD:base,
               
            })
        }
    }

    if(value === 2){
        if(this._mounted){
            let base = Object.assign({}, this.state.recolectionDistanceD);
            base.firstDistance = false;
            base.secondDistance =false;
            base.thirdDistance = true;
            base.valid = true;

            this.setState({
                recolectionDistanceD:base,
               
            })
        }
    }
}}
back={()=> {
    if(this._mounted){
        this.setState({
            sequence:1
        })
    }
}}
changeScreen={n => {
    if(this._mounted){
        this.setState({
            screen:n,
            recolectionDistanceDChanged:true,
            sequence:0
        })
    }
}}

changeScreenOmit={n => {
   if(this._mounted){
       this.setState({
           screen:n,
           sequence:0
       })
   }
}}
/>:null}




</div>:null}
    </>}
        </div>

        
    </div>
    }
}