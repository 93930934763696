import React,{lazy,Suspense} from 'react';

import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";

import './styles/css/app.css';
import CircleLoader from "./components/Loaders/CircleLoader";
import {Position, Toast, Toaster, Classes,Alert, Intent} from "@blueprintjs/core";
import logo from "./res/logo.png";
import logo_short from "./res/logo_short.png";
import firebase from "./utils/firebaseSetup";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import MyAccount from "./components/home/modals/MyAccount";
import HowItWorks from "./components/home/modals/HowItWorks";
import FAQ from "./components/home/modals/FAQ";
import MudatdoInsurance from "./components/home/modals/MudatodoInsurance";
import SideNav from './components/home/SideNav';

import ModalClientRequest from "./components/home/ModalClientRequest";
import ModalRating from "./components/home/ModalRating";


const Login = lazy(() => import("./components/Login/Login"));
const SignUp = lazy(() => import("./components/SignUp/SignUp"));

const Home = lazy(() => import("./components/home/Home"));
const Main = lazy(() => import("./components/home/Main"));
const Proposals = lazy(() =>import("./components/home/Proposals"));
const ProposalViewer = lazy(() => import("./components/home/ProposalViewer"));
const PaymentModule = lazy(() => import("./components/home/PaymentModule"));
const Messenger = lazy(() => import("./components/home/Messenger"));
const MyProfile = lazy(() => import("./components/home/MyProfile"));
const WhyUs = lazy(() => import("./components/home/WhyUs"));
const Terms = lazy(() => import("./components/home/Terms"));

class App extends React.Component {
  _mounted;
  _proposalsListener;
  toaster;
  refHandlers;
  _rateListener;
  _leadListener;
  _listenNotifications;
  _listenUser;

  constructor(props){
    super(props);

    this.state = {
      screen:1,
      toasts:[],
      auth:false,
      userData:null,
      leadData:null,
      mudaPartner:null,
      proposals:[],
      pending:false,
      lastDocument:null,
      loadMore:false,
      loadMorePending:false,
      selectedProposal:null,
      small:false,
      privacy:"",
      navbarHeight:null,
      action:null,
      myAccount:false,
      howItWorks:false,
      faq:false,
      insurance:false,
      rating:false,

      backstack:null,
      notificationsModal:false,

      notifications:[],
      pendingNotifications:false,
      loadMoreNotifications:false,
      loadMorePendingNotifications:false,
      lastDocumentNotifications:null,

      chatGate:null,

      menuOpenSmall:false,
      modalRequest:false,

      rateMudapartner:false,

    }

    this.toaster = {};
    this.refHandlers = {
        toaster:(ref) => {this.toaster = ref},
    }

  }

  addToast = (message) => {
    this.toaster.show({message: message});
}

/*
componentWillUpdate(){

  

  if(this.state.navbarHeight === null){
  let el = document.getElementById("logo")
  if(el !== null){
  el = document.getElementById("logo").getBoundingClientRect();

  if(!isNaN(Number(el.height)) && this.state.navbarHeight === null){
    if(this._mounted){
     console.log(el.height);
      this.setState({
        navbarHeight:el.height
      })
    }
  }
   
  }
}
}*/

loadMoreProposals = (leadID) => {
  if(this._mounted){
    this.setState({
      loadMorePending:true
    })
  }

  firebase.firestore().collection("proposals").where("leadID","==",leadID).orderBy("updated","desc").limit(6).get().then(async snap => {
    let proposals = []

    snap.forEach(doc => {
      let data = doc.data();
      data.id = doc.id
      proposals.push(data);
    })

    for(let i=0; i< proposals.length; i++){
      let rating = 0;

     let lastRating =  await firebase.firestore().collection("users").doc(proposals[i].driverID).collection("ratings").orderBy("created","desc").limit(1).get();

     if(lastRating.empty){
       rating = 0;
     }else {
       lastRating.forEach(doc2 => {
         rating = doc2.data().average;
       })
     }

     proposals[i].rating = rating;
    }

      if(this._mounted){
        this.setState({
          proposals:this.state.proposals.concat(proposals),
          lastDocument:snap.docs[snap.docs.length - 1],
          loadMore:snap.docs.length <6?false:true,
          pending:false,
          loadMorePending:false
        })
      }

    })
    .catch(e => {
      console.log(e);
      this.props.addToast("Algo salió mal")

      if(this._mounted){
        this.setState({
          pending:false,
          loadMorePending:false
        })
      }
    })
    
}

listenProposals = (leadID) => {
  if(this._proposalsListener !== undefined){
    this._proposalsListener();
  }

  this._proposalsListener = firebase.firestore().collection("proposals").where("leadID","==",leadID).orderBy("updated","desc").limit(6).onSnapshot(async snap => {
    let proposals = []

    snap.forEach(doc => {
      let data = doc.data();
      data.id = doc.id
      proposals.push(data);
    })

    for(let i=0; i< proposals.length; i++){
      let rating = 0;

      let user = await firebase.firestore().collection("users").doc(proposals[i].driverID).get()
     let lastRating =  await firebase.firestore().collection("users").doc(proposals[i].driverID).collection("ratings").orderBy("created","desc").limit(1).get();

     if(lastRating.empty){
       rating = 0;
     }else {
       lastRating.forEach(doc2 => {
         rating = doc2.data().average;
       })
     }

     proposals[i].rating = rating;
     proposals[i].mudaPartner = user.data();
    }

      if(this._mounted){
        this.setState({
          proposals:proposals,
          lastDocument:snap.docs[snap.docs.length - 1],
          loadMore:snap.docs.length <6?false:true,
          pending:false
        })
      }
    
  }, (err) => {
    console.log(err);
    if(err){
      this.addToast("Algo salió mal");
      if(this._mounted){
        this.setState({
          pending:false
        })
      }
    }
  })
  
}

componentWillUnmount(){
  if(this._proposalsListener !== undefined){
    this._proposalsListener();
    this._proposalsListener = undefined;
  }

  if(this._leadListener !== undefined){
    this._leadListener();
  }
}

listenRate = (mudaPartner) => {
  if(this._rateListener !== undefined){
    this._rateListener();
  }

  this._rateListener = firebase.firestore().collection("users").doc(mudaPartner).collection("ratings").where("client","==",this.state.userData.id).onSnapshot(snap => {
    if(!snap.empty){
      let data = Object.assign({}, this.state.mudaPartner);
      data.rated = true;

      if(this._mounted){
        this.setState({
          mudaPartner:data
        })
      }
    }
  }, (err) => {
    if(err){
      this.props.addToast("Algo salió mal");
      console.log(err);
    }
  })
}

mountUser =(id) => {
  this._listenUser = firebase.firestore().collection("users").doc(id).onSnapshot(async doc => {

    if(doc.exists){
    let data = doc.data();
    data.id = doc.id;

    if(doc.data().stage === 1){
      firebase.firestore().collection("users").doc(doc.data().mudaPartner).get()
      .then(async mudaPartner => {
        let ratings = await firebase.firestore().collection("users").doc(doc.data().mudaPartner).collection("ratings").orderBy("created","desc").limit(1).get();
      
        //let rated = !((await firebase.firestore().collection("users").doc(doc.data().mudaPartner).collection("ratings").where("client","==",this.state.userData.id).get()).empty);
        
        let average = 0;

        this.listenRate(doc.data().mudaPartner);

        ratings.forEach(avrg => {
          average = avrg.data().average;
        })

        let data = mudaPartner.data();
        data.id = mudaPartner.id;
        data.rating = Number(average);
       // data.rated = rated;
       
        if(this._mounted){
          this.setState({
            mudaPartner:data
          }, () => {
            if(this._listenUser !== undefined){
              this._listenUser() // clean up the listener
            }

          
          })
        }
      })
    }

    if(this._mounted){
      this.setState({
        userData:data
      })
    }
  }
  }, e => {
    console.log(e);
    this.addToast("Algo salió mal")
  })
}

  componentDidMount(){
    this._mounted = true;

     firebase.firestore().collection("itemsApp").where("category","==","privacy").get()
     .then(snap => {
       let privacy = "";

       snap.forEach(doc => {
         privacy = doc.data().item;
       })

       if(this._mounted){
         this.setState({
           privacy:privacy
         })
       }
     })
     
     /*
    if(this._mounted){
      const params = new URLSearchParams(window.location.search);
    
      if(params.has("login")){
        let login = params.get("login");
       
      this.setState({
        screen:login == "1"?0:1
      })
    }else {
      this.setState({
        screen:1
      })
    }
    }
    */

    

    let  myFunction = (x)=>{
      if (x.matches) { // If media query matches
        if(this._mounted){
          this.setState({
            small:true
          })
        }
      } else {
        if(this._mounted){
          this.setState({
            small:false
          })
        }
      }
    }

    
    
    var x = window.matchMedia("(max-width: 767px)")
    myFunction(x) // Call listener function at run time
    x.addListener(myFunction)

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.firestore().collection("users").doc(user.uid).get()
        .then(doc => {
          if(doc.exists){
          if(doc.data().userType === 0){
            if(this._mounted){
              let data = doc.data();
              data.id = doc.id
              this.setState({
                userData:data,
                auth:true
              }, () => {
                this.mountUser(data.id)
              
                if(this._mounted){
                  this.setState({
                    screen:7
                  })
                }

              this._leadListener=  firebase.firestore().collection("leads").where("clientID","==",user.uid).onSnapshot(snap => {
                  if(!snap.empty){
                     let leadData = null;

                     snap.forEach(doc => {
                       let data = doc.data();
                       data.id = doc.id;
                       leadData = data;
                     })

                     leadData.phone = this.state.userData.phone

                     if(this._mounted){
                       this.setState({
                         leadData:leadData
                       })
                     }

                     if(doc.data().stage === 0){
                     this.listenProposals(leadData.id);
                     
                     }
                  }
                  
                }, e => {
                  console.log(e);
                  this.addToast("Algo salió mal");
                })
                

                if(doc.data().stage === 1){
                  firebase.firestore().collection("users").doc(doc.data().mudaPartner).get()
                  .then(async mudaPartner => {
                    let ratings = await firebase.firestore().collection("users").doc(doc.data().mudaPartner).collection("ratings").orderBy("created","desc").limit(1).get();
                  
                    //let rated = !((await firebase.firestore().collection("users").doc(doc.data().mudaPartner).collection("ratings").where("client","==",this.state.userData.id).get()).empty);
                    
                    let average = 0;

                    this.listenRate(doc.data().mudaPartner);

                    ratings.forEach(avrg => {
                      average = avrg.data().average;
                    })

                    let data = mudaPartner.data();
                    data.id = mudaPartner.id;
                    data.rating = Number(average);
                   // data.rated = rated;
                   
                    if(this._mounted){
                      this.setState({
                        mudaPartner:data
                      }, () => {
                        if(this._listenUser !== undefined){
                          this._listenUser() // clean up the listener
                        }

                      
                      })
                    }
                  })
                }
              })
            }
          }else {
            firebase.auth().signOut();
            this.addToast("No existe el usuario");
            if(this._proposalsListener !== undefined){
              this._proposalsListener();
              this._proposalsListener = undefined;
            }

            if(this._leadListener !== undefined){
              this._leadListener()//CLean up the listener
            }
          }
        }
        })
        .catch(e => {
          console.log(e);
          this.addToast("Algo salió mal");
        })

        

        if(this._mounted){
          this.setState({
            pendingNotifications:false
          })
        }

        this._listenNotifications = firebase.firestore().collection("users").doc(user.uid).collection("notifications").orderBy("created","desc").limit(10).onSnapshot(snap => {
          let notifications = [];

          let unread = 0;

          snap.forEach(doc => {
            let data = doc.data();
            data.id = doc.id;


            notifications.push(data);

            if(doc.data().read === false){
               unread++;
            }
          })

          if(this._mounted){
            this.setState({
              notifications:notifications,
              loadMoreNotifications:snap.docs.length < 10?false:true,
              lastDocumentNotifications:snap.docs[snap.docs.length -1],
              pendingNotifications:false
            }, () => {

              /*
              if(this.state.screen === 7){
                let batch = firebase.firestore().batch();

                for(let i =0; i < this.state.notifications.length; i++){
                  if(this.state.notifications[i].chatID !== undefined && this.state.notifications[i].read === false){
                    batch.update(firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("notifications").doc(this.state.notifications[i].id), {
                      read:true
                    })
                  }
                }

                batch.commit()
                .then(() => {

                })
                .catch(e => {
                  console.log(e);
                  this.addToast("Algo salió mal")
                })
              }
              */
            })
          }
        }, (e) => {
          console.log(e);
          this.props.addToast("Algo salió mal")

          if(this._mounted){
            this.setState({
              pendingNotifications:false
            })
          }
        })
      } else {
        if(this._mounted){
          this.setState({
            userData:null,
            auth:false
          })
          /*
          if(params.has("login")){
            let login = params.get("login");
            
          this.setState({
            screen:login == "1"?0:1
          })
        }else {
          this.setState({
            screen:1
          })
        }
        */
        }
      }
    });

  }

  componentWillUnmount(){
    this._mounted = false;


    if(this._listenNotifications !== undefined){
      this._listenNotifications(); // Clean up the listener
    }
  }


  loadMoreNotifications = () => {
    if(this._mounted){
      this.setState({
        loadMorePendingNotifications:true
      })
    }

    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("notifications").orderBy("created","desc").startAfter(this.state.lastDocumentNotifications).limit(10).get()
    .then((snap) => {
      let notifications = [];

      snap.forEach(doc => {
        let data =doc.data();
        data.id = doc.id;

        notifications.push(data);
      })

      if(this._mounted){
        this.setState({
          notifications:this.state.notifications.concat(notifications),
          loadMoreNotifications:snap.docs.length < 10?false:true,
          lastDocumentNotifications:snap.docs[snap.docs.length - 1],
          loadMorePendingNotifications:false
        })
      }
    })
    .catch(e => {
      console.log(e);
      this.addToast("Algo salió mal");

      if(this._mounted){
        this.setState({
          loadMorePendingNotifications:false
        })
      }
    })
  }


  render(){
    return <div>

{this.state.rating === true?<ModalRating
userData={this.state.userData}
mudaPartner={this.state.mudaPartner}
addToast={this.addToast}
            changeScreen={() => {
                if(this._mounted){
                    this.setState({
                       rating:false
                    })
                }
            }}
            closeModal={() => {
                if(this._mounted){
                    this.setState({
                       rating:false,
                    })
                }
            }}
            />:null}

{this.state.modalRequest === true?<ModalClientRequest
            addToast={this.addToast}
            closeModal={() => {
                if(this._mounted){
                  this.setState({
                    modalRequest:false
                  })
                }
            }}
             lead={this.state.leadData}
             userData={this.state.userData}
            />:null}
      

      {this.state.insurance?<MudatdoInsurance
      addToast={this.addToast}
      close={() => {
        this.setState({
          insurance:false
        })
      }}
      />:null}
      
      {this.state.faq?<FAQ
      close={() => {
        this.setState({
          faq:false
        })
      }}
      addToast={this.addToast}
      />:null}

      {this.state.howItWorks?<HowItWorks
      addToast={this.addToast}
      close={() => {
        this.setState({
          howItWorks:false
        })
      }}
      />:null}

      {this.state.myAccount?<MyAccount

      rateMudapartner={this.state.rateMudapartner}
       action={this.state.action}
       close={() => {
         this.setState({
           myAccount:false
         })
       }}
       changeScreen={n => {
         if(this._mounted){
           this.setState({
             screen:n
           })
         }
       }}
       cleanAction={() => {
         if(this._mounted){
           this.setState({
             action:null
           })
         }
       }}
       mudaPartner={this.state.mudaPartner}
       userData={this.state.userData}
       leadData={this.state.leadData}
       name={this.state.userData.name}
       stage={this.state.userData.stage}
       addToast={this.addToast}
      />:null}
      <Modal open={this.state.notificationsModal} onClose={() => {
        if(this._mounted){
          this.setState({
            notificationsModal:false
          })
        }
      }} center>

        <h4 className="text-center mt-5 mb-2">NOTIFICACIONES</h4>
        {this.state.pendingNotifications?<div className="mt-4 flex-center"><div className="spinner-border"></div></div>:
        this.state.notifications.length < 1?<p className="text-center mt-4">No hay notificationes</p>:
        this.state.notifications.map((e,i) => {
          return <div className="notification-element mt-2" key={i}>
             <h6>{e.title}</h6>
             <p className="mt-2">{e.content}</p>

             <p className="mt-2"><small>{e.created.toDate().getDate()}/{e.created.toDate().getMonth() + 1}/{e.created.toDate().getFullYear()} {e.created.toDate().getHours()}:{e.created.toDate().getMinutes()}</small></p>
          </div>
        })}

        {this.state.loadMorePendingNotifications?<div className="text-center mt-3"><div className="spinner-border"></div></div>:this.state.loadMoreNotifications === true?<div className="mt-3 flex-center"><button className="btn" onClick={() => {
          this.loadMoreNotifications();
        }}>
          <span>Cargar más</span>
          </button></div>:null}
      </Modal>
     
      
      
      <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={this.refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {this.state.toasts.map((toast) => <Toast action={{onClick:() => {}, text:"Resend"}} {...toast} />)}
                </Toaster>
      <Suspense fallback={<CircleLoader/>}>
        <Router>

        {this.state.small === false?<div className="logo flex-end shadow-sm sticky-top special-navbar" id="logo">
        {true !== true?<img src={logo} onClick={() => {
          if(firebase.auth().currentUser === null){
          if(this._mounted){
            this.setState({
              screen:0
            })
          }
        }
        }}/>:null}


        {this.state.small === true?<button className='btn' onClick={() => {
          if(this._mounted){
            this.setState({
              menuOpenSmall:true
            })
          }
        }}>
          <i className='material-icons align-middle'>menu</i>
        </button>:null}

{this.state.screen !== 0 && this.state.screen !== 1 && this.state.small === false?<div className="account-functions flex-center" id="account-functions">
        {true !== true?<button className="btn" onClick={() => {

if(this.state.leadData !== null && this.state.userData !== null){
          this.setState({
            screen:7
          })
        }else {
          this.addToast("Espere...")
        }
        }}>
          <i className="material-icons align-middle">sms</i>
        </button>:null}
        <a className={`${this.state.screen !== 7?"normal":"light"} m-3`} aria-disabled={true} onClick={() => {
          this.setState({
            howItWorks:true
          })
        }}>COMO FUNCIONA</a>

        <a className={`${this.state.screen !== 7?"normal":"light"} m-3`} aria-disabled={true} onClick={() => {
          this.setState({
            faq:true
          })
        }}>PREGUNTAS Y RESPUESTAS</a>

        <a className={`${this.state.screen !== 7?"normal":"light"} m-3`} aria-disabled={true} onClick={() => {
          this.setState({
            insurance:true
          })
        }}>SEGURO MUDATODO</a>

        
        <button className="btn notification-component" onClick={() => {
          if(this._mounted){
            this.setState({
              notificationsModal:true
            }, () => {
              let count = 0;

          for(let i =0; i < this.state.notifications.length; i++){
            if(this.state.notifications[i].read === false){
              count++;
            }
          }

          if(count > 0){
            let batch = firebase.firestore().batch();

            for(let i =0; i < this.state.notifications.length; i++){
              batch.update(firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("notifications").doc(this.state.notifications[i].id), {
                read:true
              })
            }

            batch.commit()
            .then(() => {

            })
            .catch(e => {
              console.log(e);
              this.addToast("Algo salió mal")
            })
          }
            })
          }
        }}>
          <i className="material-icons align-middle">notifications_none</i>

          {(() => {
            let count = 0;

            for(let i =0; i < this.state.notifications.length; i++){
             if(this.state.notifications[i].read === false){
                count++;
              }

        
            }

            if(count < 1){
              return null;
            }else {
             return  <div className="notification-counter"><p>{count}</p></div>
            }
          })()}
        </button>
                
                <a className="normal m-3" onClick={() => {
              
                  firebase.auth().signOut();
                  window.location.href = "https://clientes.mudatodo.com/?login=1";
             
                }}>SALIR</a>
            </div>:null}
      </div>:null}

        {this.state.screen !== 0 && this.state.screen !== 1 && this.state.small?<nav className="navbar navbar-inverse navbar-expand-md navbar-dark sticky-top">

  

 
{this.state.backstack === null?<button className="btn mr-auto" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" onClick={() => {
  if(this._mounted){
    this.setState({
      menuOpenSmall:true
    })
  }
}}>
  <i className="material-icons align-middle">menu</i>
</button>:<button className="btn btn-sm" onClick={() => {
  if(this._mounted){
     let base = Object.assign({}, this.state.backstack);

     base.state = base.to;

     this.setState({
       backstack:base
     })
  }
}}><i className="material-icons align-middle">arrow_back_ios</i></button>}

{true !== true?<a className="navbar-brand" href="#" onClick={() => {
  if(this.state.screen !== 3){
    if(this._mounted){
      this.setState({
        screen:3
      })
    }
  }
}}><img src={logo_short}/></a>:null}

<ul className="navbar-nav ml-auto shadow-none">
  <li className="nav-item">
  <button className="btn notification-component" onClick={() => {
    if(this._mounted){
      this.setState({
        notificationsModal:true
      }, () => {
        let count = 0;

        for(let i =0; i < this.state.notifications.length; i++){
          if(this.state.notifications[i].read === false){
            count++;
          }
        }

        if(count > 0){
          let batch = firebase.firestore().batch();

          for(let i =0; i < this.state.notifications.length; i++){
            batch.update(firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("notifications").doc(this.state.notifications[i].id), {
              read:true
            })
          }

          batch.commit()
          .then(() => {

          })
          .catch(e => {
            console.log(e);
            this.addToast("Algo salió mal")
          })
        }
      })
    }
  }}>
        <i className="material-icons align-middle">notifications_none</i>

        {(() => {
          let count = 0;

          for(let i =0; i < this.state.notifications.length; i++){
            if(this.state.notifications[i].read === false){
              count++;
            }
          }

          if(count < 1){
            return 
          }else {
           return  <div className="notification-counter"><p>{count}</p></div>
          }
        })()}
      </button>
  </li>
</ul>

{true !== true?<div className="collapse navbar-collapse" id="collapsibleNavbar">
  <ul className="navbar-nav">
    <li className="nav-item">
    <a className={`nav-link ${this.state.screen !== 3?"normal":"light"} m-3`} aria-disabled={true} onClick={() => {
                if(this.state.screen !== 3){
                  if(this._mounted){
                    this.setState({
                      myAccount:true
                    })
                  }
                }
              }}>MI CUENTA</a>
    </li>

    <li className="nav-item">
    <button className="btn" onClick={() => {

      if(this.state.leadData !== null && this.state.userData !== null){
        this.setState({
          screen:7
        })
      }else {
        this.addToast("Espere...")
      }
      }}>
        <i className="material-icons align-middle">sms</i>
      </button>
    </li>

  

    <li className="nav-item">
    <a className="nav-link normal m-3" onClick={() => {
            
            firebase.auth().signOut()
          //  window.location.href = "https://clientes.mudatodo.com/?login=1";
       
          }}>SALIR</a>
    </li>
  
  </ul>
</div>:null}
</nav>:null}

          {this.state.auth && this.state.userData !== null && this.state.small === true?<div className="bottom-mobile-bar flex-center">
        <button className="btn btn-outline-dark ml-2" onClick={() => {
          window.open(`https://api.whatsapp.com/send?phone=5219983047709&text=Hola,%20quiero%20dar%20seguimiento%20a%20mi%20solicitud%20de%20mudanza,%20mi%20nombre%20es%20${this.state.userData.name}`)
        }}>
                Ayuda por Whatsapp
            </button>
          </div>:null}

        {this.state.auth && this.state.userData !== null?this.state.small === false || (this.state.small === true && this.state.menuOpenSmall)?<SideNav
  
        rateMudapartner={() => {
          if(this._mounted){
            this.setState({
              rating:true
            })
          }
        }}
        openNotifications={() => {
          if(this._mounted){
            this.setState({
              notificationsModal:true
            })
          }
        }}
        openModalRequest={() => {
          if(this._mounted){
            this.setState({
              modalRequest:true
            })
          }
        }}
        close={() => {
           if(this._mounted){
             this.setState({
               menuOpenSmall:false
             })
           }
        }}
        openFaq={() => {
          this.setState({
            faq:true
          })
        }}
          action={this.state.action}
          changeScreen={n => {
            if(this._mounted){
              this.setState({
                screen:n
              })
            }
          }}
          cleanAction={() => {
            if(this._mounted){
              this.setState({
                action:null
              })
            }
          }}
          mudaPartner={this.state.mudaPartner}
          userData={this.state.userData}
          leadData={this.state.leadData}
          name={this.state.userData.name}
          stage={this.state.userData.stage}
          small={this.state.small}
          addToast={this.addToast}/>:null:null}

          <Routes> 
        <Route path='/my-account'  element={this.state.auth && this.state.userData !== null?<MyProfile
        addToast={this.addToast}
        userData={this.state.userData}

        action={this.state.action}
        changeScreen={n => {
          if(this._mounted){
            this.setState({
              screen:n
            })
          }
        }}
        cleanAction={() => {
          if(this._mounted){
            this.setState({
              action:null
            })
          }
        }}
        mudaPartner={this.state.mudaPartner}
      
        leadData={this.state.leadData}
        name={this.state.userData.name}
        stage={this.state.userData.stage}
 
        small={this.state.small}
        />:<Navigate to={"/"}/>}/>
        <Route path='/' element={this.state.auth && this.state.userData !== null?<Home
        rateMudapartner={() => {
          if(this._mounted){
            this.setState({
              rating:true
            })
          }
        }}
        chatGate={this.state.chatGate}
        clearChatGate={() => {
          if(this._mounted){
            this.setState({
              chatGate:null
            })
          }
        }}
        editLead={() => {
          if(this._mounted){
            this.setState({
              screen:3
            }, () => {
              if(this._mounted){
                this.setState({
                  action:0
                })
              }
            })
          }
        }}
         navbarHeight={this.state.navbarHeight}
         addToast={this.addToast}
         leadData={this.state.leadData}
         userData={this.state.userData}
         backstack={this.state.backstack}
         setBackstack={(obj) => {
           if(this._mounted){
             this.setState({
               backstack:obj
             })
           }
         }}

   
        lead={this.state.leadData}
        proposals={this.state.proposals}
        pending={this.state.pending}
        loadMore={this.state.loadMore}
        loadMorePending={this.state.loadMorePending}
        lastDocument={this.state.lastDocument}
        small={this.state.small}

         openProposal={proposal => {
           if(this._mounted){
           
             this.setState({
               selectedProposal:proposal
             }, () => {
               if(this._mounted){
                 this.setState({
                   screen:5
                 })
               }
             })
           }
         }}
         />:<Navigate to="/sign-up"/>}/>

        <Route path='/login' element={this.state.auth && this.state.userData !== null?<Navigate to="/"/>:<Login
        addToast={this.addToast}
        changeScreen={() => {
          if(this._mounted){
            this.setState({
              screen:1
            })
          }
        }}
        />}/>
        {true !== true && this.state.screen == 0?<Login
        addToast={this.addToast}
        changeScreen={() => {
          if(this._mounted){
            this.setState({
              screen:1
            })
          }
        }}
        />:null}

        <Route path='/sign-up' element={this.state.auth && this.state.userData !== null?<Navigate to="/"/>:<SignUp
        privacy={this.state.privacy}
        changeScreen={(n) => {
          if(this._mounted){
            this.setState({
              screen:n
            })
          }
        }}
        addToast={this.addToast}
        />}/>
        
        {true !== true && this.state.screen === 1?<SignUp
        privacy={this.state.privacy}
        changeScreen={(n) => {
          if(this._mounted){
            this.setState({
              screen:n
            })
          }
        }}
        addToast={this.addToast}
        />:null}

        <Route path='/why-us' element={this.state.auth && this.state.userData !== null?<WhyUs
        userData={this.state.userData}
        addToast={this.addToast}
        />:<Navigate to="/"/>}/>

<Route path='/terms' element={this.state.auth && this.state.userData !== null?<Terms
        userData={this.state.userData}
        addToast={this.addToast}
        />:<Navigate to="/"/>}/>

        <Route path='/home' element={this.state.userData !== null?<Main
        action={this.state.action}
        changeScreen={n => {
          if(this._mounted){
            this.setState({
              screen:n
            })
          }
        }}
        cleanAction={() => {
          if(this._mounted){
            this.setState({
              action:null
            })
          }
        }}
        mudaPartner={this.state.mudaPartner}
        userData={this.state.userData}
        leadData={this.state.leadData}
        name={this.state.userData.name}
        stage={this.state.userData.stage}
        addToast={this.addToast}
        />:<Navigate to="/"/>}/>

        {true !== true && this.state.screen===3?
        <Main
        action={this.state.action}
        changeScreen={n => {
          if(this._mounted){
            this.setState({
              screen:n
            })
          }
        }}
        cleanAction={() => {
          if(this._mounted){
            this.setState({
              action:null
            })
          }
        }}
        mudaPartner={this.state.mudaPartner}
        userData={this.state.userData}
        leadData={this.state.leadData}
        name={this.state.userData.name}
        stage={this.state.userData.stage}
        addToast={this.addToast}
        />:null}

        <Route path='/proposals' element={<Proposals
        back={() => {
          if(this._mounted){
            this.setState({
              screen:3
            })
          }
        }}
        loadMoreProposals={(id) => {
          this.loadMoreProposals(id);
        }}
        lead={this.state.leadData}
        proposals={this.state.proposals}
        pending={this.state.pending}
        loadMore={this.state.loadMore}
        loadMorePending={this.state.loadMorePending}
        lastDocument={this.state.lastDocument}
         addToast={this.props.addToast}
         openProposal={proposal => {
           if(this._mounted){
           
             this.setState({
               selectedProposal:proposal
             }, () => {
               if(this._mounted){
                 this.setState({
                   screen:5
                 })
               }
             })
           }
         }}
        />}/>

        {true !== true && this.state.screen === 4?
        <Proposals
        back={() => {
          if(this._mounted){
            this.setState({
              screen:3
            })
          }
        }}
        loadMoreProposals={(id) => {
          this.loadMoreProposals(id);
        }}
        lead={this.state.leadData}
        proposals={this.state.proposals}
        pending={this.state.pending}
        loadMore={this.state.loadMore}
        loadMorePending={this.state.loadMorePending}
        lastDocument={this.state.lastDocument}
         addToast={this.props.addToast}
         openProposal={proposal => {
           if(this._mounted){
           
             this.setState({
               selectedProposal:proposal
             }, () => {
               if(this._mounted){
                 this.setState({
                   screen:5
                 })
               }
             })
           }
         }}
        />:null}

        <Route path='/proposal/:id' element={ <ProposalViewer
        openChat={(id) => {
          if(this._mounted){
            this.setState({
              screen:7
            }, () => {
              if(this._mounted){
                this.setState({
                  chatGate:id
                })
              }
            })
          }
        }}
        addToast={this.addToast}
        lead={this.state.leadData}
        proposal={this.state.selectedProposal}
        userData={this.state.userData}
        changeScreen={s => {
          if(this._mounted){
            this.setState({
              screen:s
            })
          }
        }}
        back={() => {
          if(this._mounted){
            this.setState({
              screen:4
            })
          }
        }}
        />}/>

        {true !== true && this.state.screen ===5? 
        <ProposalViewer
        openChat={(id) => {
          if(this._mounted){
            this.setState({
              screen:7
            }, () => {
              if(this._mounted){
                this.setState({
                  chatGate:id
                })
              }
            })
          }
        }}
        addToast={this.addToast}
        lead={this.state.leadData}
        proposal={this.state.selectedProposal}
        userData={this.state.userData}
        changeScreen={s => {
          if(this._mounted){
            this.setState({
              screen:s
            })
          }
        }}
        back={() => {
          if(this._mounted){
            this.setState({
              screen:4
            })
          }
        }}
        />
        :null}

        <Route path='/payment-module' element={this.state.userData !== null?<PaymentModule
        phone={this.state.userData.phone}
        back={() => {
          if(this._mounted){
            this.setState({
              screen:5
            })
          }
        }}
        addToast={this.addToast}
        proposal={this.state.selectedProposal}
        />:null}/>

        {true !== true && this.state.screen === 6?
        <PaymentModule
        phone={this.state.userData.phone}
        back={() => {
          if(this._mounted){
            this.setState({
              screen:5
            })
          }
        }}
        addToast={this.addToast}
        proposal={this.state.selectedProposal}
        />:null}

        <Route path='/messenger' element={<Messenger
        chatGate={this.state.chatGate}
        clearChatGate={() => {
          if(this._mounted){
            this.setState({
              chatGate:null
            })
          }
        }}
        editLead={() => {
          if(this._mounted){
            this.setState({
              screen:3
            }, () => {
              if(this._mounted){
                this.setState({
                  action:0
                })
              }
            })
          }
        }}
         navbarHeight={this.state.navbarHeight}
         addToast={this.addToast}
         leadData={this.state.leadData}
         userData={this.state.userData}
         backstack={this.state.backstack}
         setBackstack={(obj) => {
           if(this._mounted){
             this.setState({
               backstack:obj
             })
           }
         }}

         openProposal={proposal => {
         
          if(this._mounted){
          
            this.setState({
              selectedProposal:proposal
            }, () => {
              if(this._mounted){
                this.setState({
                  screen:5
                })
              }
            })
          }
        }}
          />}/>

        {true !== true && this.state.screen === 7?
        <Messenger
        chatGate={this.state.chatGate}
        clearChatGate={() => {
          if(this._mounted){
            this.setState({
              chatGate:null
            })
          }
        }}
        editLead={() => {
          if(this._mounted){
            this.setState({
              screen:3
            }, () => {
              if(this._mounted){
                this.setState({
                  action:0
                })
              }
            })
          }
        }}
         navbarHeight={this.state.navbarHeight}
         addToast={this.addToast}
         leadData={this.state.leadData}
         userData={this.state.userData}
         backstack={this.state.backstack}
         setBackstack={(obj) => {
           if(this._mounted){
             this.setState({
               backstack:obj
             })
           }
         }}

         openProposal={proposal => {
         
          if(this._mounted){
          
            this.setState({
              selectedProposal:proposal
            }, () => {
              if(this._mounted){
                this.setState({
                  screen:5
                })
              }
            })
          }
        }}
          />
        :null}
        </Routes>
        </Router>
      </Suspense>
    </div>
  }
}


export default App;
