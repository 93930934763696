import React from "react";
import QuestionBlock from "../QuestionBlock";

class FAQ extends React.Component {
    _mounted;

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <div className="modal-wrapper">
            <button className="btn btn-close-modal" onClick={() => {
                this.props.close();
            }}>
                <i className="material-icons align-middle">clear</i>
            </button>

            <div className="modal-content">
            <h2 className="text-center">PREGUNTAS Y RESPUESTAS</h2>
               <div className="flex-center mt-5">
                   <QuestionBlock question={"¿Porqué es Importante Reservar?"} answer={<>
                   La reservación o apartado es un abono del precio total, que nos permite garantizar la disponibilidad para tu mudanza. Puede 
                   estar tranquilo que su servicio se realizará bajo las condiciones acordadas. <b>La reserva es 100% reembolsable en caso de que 
                   canceles tu mudanza, y flexible para cambios de fecha.*</b>
                   </>}/>
               </div>
               <div className="flex-center mt-3">
                   <QuestionBlock question={"Aún no tengo fecha para la mudanza"} answer={`
                   No hay problema, puedes reservar y cambiar la fecha cuantas veces lo necesites. Queremos ayudarte a disminuir el estrés natural 
                   por los cambios que vives
                   `}/>
               </div>

               <div className="flex-center mt-3">
                   <QuestionBlock question={"No estoy seguro de haber ingresado el volumen exacto"} answer={<>
                   <b>Somos Flexibles</b>, si has ingresado correctamente los elementos, el volumen será 90% correcto. De todas maneras, una vez reservado,
                    agendaremos una llamada o visita de confirmación de volumen para asegurar que todo esté bien
                   </>}/>
               </div>

               <div className="flex-center mt-3">
                   <QuestionBlock question={"¿Quién realizará mi mudanza?"} answer={<>
                   Lo realizará uno de nuestros <b><i>mudapartners</i></b>, quienes son personal de amplia experiencia, y que han sido previamente seleccionados y capacitados para 
                   brindarle un servicio profesional y a la altura de sus necesidades
                   </>}/>
               </div>

               <div className="flex-center mt-3">
                   <QuestionBlock question={"¿Qué Incluye la Propuesta?"} answer={<>
                   Todas las propuestas son ofertadas de manera independiente por cada uno de nuestros <b><i>mudapartners</i></b>, 
                   y cada propuesta es única y diferente, por lo que usted puede seleccionar la que incluya las opciones que más se ajuste a sus requerimientos
                   </>}/>
               </div>

               <div className="flex-center mt-3">
                   <QuestionBlock question={"¿Qué hago después de Reservar?"} answer={<>
                   Nuestro mudapartner seleccionado se pondrá en contacto con usted para coordinar 
                   los detalles de la mudanza, también podrá ingresar a su cuenta generada autmáticamente para ver los 
                   datos de contacto del responsable de su servicio. Nosotros seguiremos en contacto para su tranquilidad
                   </>}/>
               </div>

               <div className="flex-center mt-3">
                   <QuestionBlock question={"Solicito Factura del Servicio"} answer={<>
                   No hay problema, contáctenos y nuestro personal contable atenderá su solicitud de facturación ya sea persona 
                   física o moral
                   </>}/>
               </div>
               
           
            </div>
        </div>
    }
}

export default FAQ