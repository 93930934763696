import React from "react";
import logo_mudanzas_1 from "../../res/logo_mudanzas_1.png";
import ReactStarRating from "react-star-ratings-component";
import Rating from "react-rating";
import logo_truck_mudatodo from "../../res/logo_truck_mudatodo.png";
import firebase from "../../utils/firebaseSetup";
import KeywordGeneration from "../../utils/KeywordsGeneration";

export default class ModalClientRequest extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        this.state = {
            calification:0,
            comment:"",
            loading:false,
            state:0
        }
    }

    componentDidMount(){
        this._mounted = true;

        console.log(this.props.mudaPartner);
        console.log(firebase.auth().currentUser.uid)

        if(this.props.mudaPartner !== null){
        if(this.props.mudaPartner.rated === true){
            if(this._mounted){
                this.setState({
                    state:1
                })
            }
        }
    }

    window.document.body.style.overflow = "hidden";
      
    }


    componentWillReceiveProps(nextProps){

        if(nextProps.mudaPartner !== null){
        if(nextProps.mudaPartner.rated === true){
            if(this._mounted){
                this.setState({
                    state:1
                })
            }
        }
    }
    }

    componentWillUnmount(){
        this._mounted = false;

        window.document.body.style.overflow = "visible";
    }

    sendRating = () => {
    if(this._mounted){
        this.setState({
            loading:true
        })
    }

      firebase.firestore().collection("users").doc(this.props.mudaPartner.id).collection("ratings").orderBy("created","desc").limit(1)
      .get()
      .then(snap => {
          if(snap.empty){

         


                let batch = firebase.firestore().batch();

               

                let ratingId = firebase.firestore().collection("ratings").doc().id;

                batch.set(firebase.firestore().collection("users").doc(this.props.mudaPartner.id).collection("ratings").doc(ratingId), {
                    created:firebase.firestore.Timestamp.now(),
                  comment:this.state.comment,
                  calification:Number(this.state.calification),
                  average:Number(this.state.calification),
                  position:1,
                  client:this.props.userData.id,
                  state:"pending"
                  })

                  let generalRatingID = firebase.firestore().collection("ratings").doc().id;

                  batch.set(firebase.firestore().collection("ratings").doc(generalRatingID), {
                      created:firebase.firestore.Timestamp.now(),
                      mudaPartner:this.props.mudaPartner.company,
                      mudaPartnerID:this.props.mudaPartner.id,
                      client:this.props.userData.id,
                      state:"pending",
                      clientName:this.props.userData.name,
                      calification:Number(this.state.calification),
                      comment:this.state.comment,
                      ratingID:ratingId,
                      keywords:KeywordGeneration.generateKeywords(this.props.userData.name).concat(KeywordGeneration.generateKeywords(this.props.mudaPartner.company))
                  })


              batch.commit()
              .then(() => {
                  if(this._mounted){
                      this.setState({
                          loading:false,
                          state:1
                      })
                  }
              })
              .catch(e => {
                  console.log(e);
                  if(this._mounted){
                      this.setState({
                          loading:false
                      })
                  }

                  this.props.addToast("Algo salió mal");
              })

         

          }else {
              let lastRating = null;
              snap.forEach(doc => {
                  lastRating = doc.data();
              });

              

       

                let batch = firebase.firestore().batch();

         

                let ratingId = firebase.firestore().collection("ratings").doc().id

                batch.set(firebase.firestore().collection("users").doc(this.props.mudaPartner.id).collection("ratings").doc(ratingId), {
                  created:firebase.firestore.Timestamp.now(),
                  comment:this.state.comment,
                  position:Number(lastRating.position) + 1,
                  calification:Number(this.state.calification),
                  average:((Number(lastRating.average) * Number(lastRating.position)) + Number(this.state.calification))/(Number(lastRating.position) + 1),
                  client:this.props.userData.id 
                })

                let generalRatingID = firebase.firestore().collection("ratings").doc().id;

                  batch.set(firebase.firestore().collection("ratings").doc(generalRatingID), {
                    created:firebase.firestore.Timestamp.now(),
                    mudaPartner:this.props.mudaPartner.company,
                    mudaPartnerID:this.props.mudaPartner.id,
                    client:this.props.userData.id,
                    state:"pending",
                    clientName:this.props.userData.name,
                    calification:Number(this.state.calification),
                    comment:this.state.comment,
                    ratingID:ratingId,
                    keywords:KeywordGeneration.generateKeywords(this.props.userData.name).concat(KeywordGeneration.generateKeywords(this.props.mudaPartner.company))
                  })

              batch.commit()
              .then(() => {
                  if(this._mounted){
                      this.setState({
                          loading:false,
                          state:1
                      })
                  }
                  this.props.addToast("Usuario Calificado")
              })
              .catch(e => {
                  console.log(e);
                  if(this._mounted){
                      this.setState({
                          loading:false
                      })
                  }
                  this.props.addToast("Algo salió mal");
              })

          
          }
      })
      .catch(e => {
          console.log(e);
          if(this._mounted){
              this.setState({
                  loading:false
              })
          }
          this.props.addToast("Algo salió mal")
      })
    }

    render(){
        return <div className="modal-wrapper">
            <button className="btn-close-modal btn" onClick={this.props.closeModal}>
                <i className="material-icons align-middle">clear</i>
            </button>
            <div className="modal-content">
                {this.state.state === 0?<>
                <div className="flex-center company-logo-absolute">
                    <img src={this.props.mudaPartner.photoURL !== undefined && this.props.mudaPartner.photoURL !== ""?this.props.mudaPartner.photoURL:logo_mudanzas_1} width="120px" style={{
                        borderRadius:"50%"
                    }} />
                </div>

                 {this.props.mudaPartner !== null?<p className="text-center modal-home-text mt-3"><b>{this.props.mudaPartner.company}</b></p>:<div className="mt-3">
                     <div className="spinner-border"></div>
                 </div>}
                 {this.props.mudaPartner !== null?<div className="flex-center mt-2">
                 {true !== true?<ReactStarRating
                  numberOfStar={5}
                  numberOfSelectedStar={this.props.mudaPartner.rating}
                  colorFilledStar="#f9ac19"
                  colorEmptyStar="#c4c4c4"

                  starSize="12px"
                  spaceBetweenStar="5px"
                  disableOnSelect={true}
                  onSelectStar={val => {
                  console.log(val);
                 }}
               />:null}

<Rating  readonly={true} initialRating={this.props.mudaPartner.rating} emptySymbol={<i className="material-icons align-middle" style={{color:"lightgrey"}}>star</i>} fullSymbol={<i className="material-icons align-middle" style={{color:"#f9ac19"}}>star</i>} />

                 </div>:<div className="mt-3">
                     <div className="spinner-border"></div>
                 </div>}

                 <p className="mt-3 text-center modal-home-text"><b>¿Cómo estuvo el servicio?</b></p>

                <p className=" mt-3 text-center modal-home-text">Tu opinion nos ayuda a mejorar. gracias</p>

                {this.state.mudaPartner !== null?<>
                <div className="flex-center mt-3">
                {true !== true?<ReactStarRating
                  numberOfStar={5}
                  numberOfSelectedStar={this.state.calification}
                  colorFilledStar="#f9ac19"
                  colorEmptyStar="#c4c4c4"
                  starSize="25px"
                  spaceBetweenStar="10px"
                  disableOnSelect={false}
                  onSelectStar={val => {
                  if(this._mounted){
                      this.setState({
                          calification:val
                      })
                  }
                 }}
               />:null}

<Rating  onChange={val => {
                  if(this._mounted){
                      this.setState({
                          calification:val
                      })
                  }
                 }} fractions={4} initialRating={this.state.calification} emptySymbol={<i className="material-icons align-middle" style={{color:"lightgrey", fontSize:"40px"}}>star</i>} fullSymbol={<i className="material-icons align-middle" style={{color:"#f9ac19", fontSize:"40px"}}>star</i>} />

                </div>

               {this.state.loading === false?<div className=" mt-3">
                    <div className="form-group">
                        <textarea className="form-control" rows="4" style={{resize:"none"}} value={this.state.comment} onChange={e => {
                            if(this._mounted){
                                this.setState({
                                    comment:e.currentTarget.value
                                })
                            }
                        }} placeholder="Opinión..."></textarea>
                    </div>

                    <div className="flex-center">
                    <button className=" btn btn-custom-1 mt-3 btn-block" onClick={this.sendRating}>
                        <span><b>ENVIAR OPINIÓN</b></span>
                    </button>
                    </div>
                </div>:<div className="mt-3">
                     <div className="spinner-border"></div>
                 </div>}
                </>:<div className="flex-center p-2">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>}

                </>:<>
                <div className="flex-center mudatodo-img mt-2">
                    <img src={logo_truck_mudatodo}/>
                </div>

                <p className="mt-4 text-center"><b>GRACIAS POR TU OPINIÓN</b></p>

                <p className="mt-4 text-center">Esperamos contar con tu preferencia nuevamente</p>
                </>}
            </div>
        </div>
    }
}