import React from "react";
import logo_mudanzas_1 from "../../res/logo_mudanzas_1.png";

export default class ModalClientRequest extends React.Component {
    _mounted;

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <div className="modal-home-wrapper" style={{
            zIndex:20
        }}>
            <button className="btn-close-modal " style={{
            zIndex:20
        }} onClick={this.props.closeModal}>
                <i className="material-icons align-middle">clear</i>
            </button>
            <div className="modal-home-company">
                <div className="flex-center">
                    <img src={logo_mudanzas_1} width="150px"/>
                </div>

                <h1 className="mt-5 text-center"><b>Aún no has reservado tu mudanza</b></h1>

                <div className="action-text" onClick={this.props.changeScreen}>
                    <h4 className="text-center"><b>SELECCIONA UNA PROPUESTA</b></h4>
                </div>
            </div>
        </div>
    }
}