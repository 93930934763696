import React from "react";
import deilicado2 from "../../res/delicado2.png";

export default class ModalInventory extends React.Component {
    _mounted;

    constructor(props){
        super(props);

    }

  

    render(){
        return <div className="modal-inventory-wrapper">
            <div className="modal-inventory">
                <div className="flex-between">
                   <h1>{this.props.header}</h1>

                   <button className="btn btn-custom-1" onClick={()=> {
                       this.props.saveData(this.props.target);
                   }}>
                       <span>GUARDAR</span>
                   </button>
                </div>
                {this.props.onlyToggle === false || this.props.onlyToggle === undefined?<>
                <div className="row mt-2">
                    <div className="col-sm-8">
                        <div className="fragile-indicator-box flex-center">
                            <p>Marcar si es elemento <b>DELICADO</b> para considerar embalaje</p>

                            <div className="fragile-icon ml-2" style={{
                                backgroundSize:"contain",
                                backgroundRepeat:"no-repeat",
                                backgroundImage:`url(${deilicado2})`,
                                backgroundPosition:"center",
                            }}>
                                <button className="btn-selected">
                                    <i className="material-icons align-middle">done</i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 flex-center">
                       <h1>{this.props.vol} m3</h1>
                    </div>
                </div>


                <div className="row mt-4">
                    <div className="col-sm-6">
                        {this.props.leftElements.map((e,i) => {
                            return <div className="modal-element" key={i}>
                                <div className="row">
                                    <div className="col-sm-6">
                                       <p>{e.title}</p>
                                    </div>
                                    <div className="col-sm-6 flex-end">
                                        <button className=" btn btn-selectable" onClick={() => {
                                             this.props.updateQuantity("left","remove",1, i)
                                         }}>
                                            <i className="material-icons align-middle">remove</i>
                                        </button>

                                         <p className="quantity">{e.quantity}</p>

                                         <button className="btn btn-selectable" onClick={() => {
                                             this.props.updateQuantity("left","add",1, i)
                                         }}>
                                            <i className="material-icons align-middle">add</i>
                                        </button>

                                        <div className="fragile-icon ml-2" style={{
                                           backgroundSize:"contain",
                                           backgroundRepeat:"no-repeat",
                                           backgroundImage:`url(${deilicado2})`,
                                           backgroundPosition:"center",
                                           cursor:"pointer"
                                          }} onClick={() => {
                                              this.props.setFragile("left", i)
                                          }}>
                                         {e.fragile?<button className="btn-selected">
                                              <i className="material-icons align-middle">done</i>
                                         </button>:null}
                                       </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>

                    <div className="col-sm-6">
                    {this.props.rightElements.map((e,i) => {
                            return <div className="modal-element" key={i}>
                                <div className="row">
                                    <div className="col-sm-6">
                                       <p>{e.title}</p>
                                    </div>
                                    <div className="col-sm-6 flex-end">
                                        <button className=" btn btn-selectable" onClick={() => {
                                             this.props.updateQuantity("right","remove",1, i)
                                         }}>
                                            <i className="material-icons align-middle">remove</i>
                                        </button>

                                         <p className="quantity">{e.quantity}</p>

                                         <button className="btn btn-selectable" onClick={() => {
                                             this.props.updateQuantity("right","add",1, i)
                                         }}>
                                            <i className="material-icons align-middle">add</i>
                                        </button>

                                        <div className="fragile-icon ml-2" style={{
                                           backgroundSize:"contain",
                                           backgroundRepeat:"no-repeat",
                                           backgroundImage:`url(${deilicado2})`,
                                           backgroundPosition:"center",
                                           cursor:"pointer"
                                          }} onClick={() => {
                                            this.props.setFragile("right", i)
                                        }}>
                                         {e.fragile?<button className="btn-selected">
                                              <i className="material-icons align-middle">done</i>
                                         </button>:null}
                                       </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                </>:null}

                {this.props.onlyToggle === true?<>

                    <div className="row mt-2">
                    <div className="col-sm-8">
                        <div className="fragile-indicator-box flex-center">
                            <p>Marcar si es elemento <i className="material-icons align-middle">done</i> para considerar embalaje</p>

                            
                        </div>
                    </div>
                    <div className="col-sm-4 flex-center">
                       <h1>{this.props.vol} m3</h1>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-4">
                        {this.props.leftElements.map((e,i) => {
                            return <div className="modal-element flex-between" key={i}>
                                <p>{e.title}</p>

                                <button className={`btn ${e.active?"btn-toggle-active":"btn-toggle-inactive"}`} onClick={() => {
                                    this.props.toggleState("left",i)
                                }}>
                                    <i className="material-icons align-middle">{e.active?"done":"add"}</i>
                                </button>
                            </div>
                        })}
                    </div>

                    <div className="col-sm-4">
                    {this.props.middleElements.map((e,i) => {
                            return <div className="modal-element flex-between" key={i}>
                                <p>{e.title}</p>

                                <button className={`btn ${e.active?"btn-toggle-active":"btn-toggle-inactive"}`} onClick={() => {
                                    this.props.toggleState("middle",i);
                                }}>
                                    <i className="material-icons align-middle">{e.active?"done":"add"}</i>
                                </button>
                            </div>
                        })}
                    </div>

                    <div className="col-sm-4">
                    {this.props.rightElements.map((e,i) => {
                            return <div className="modal-element flex-between" key={i}>
                                <p>{e.title}</p>

                                <button className={`btn ${e.active?"btn-toggle-active":"btn-toggle-inactive"}`} onClick={() => {
                                    this.props.toggleState("right",i)
                                }}>
                                    <i className="material-icons align-middle">{e.active?"done":"add"}</i>
                                </button>
                            </div>
                        })}
                    </div>
                </div>
                </>:null}
            </div>
        </div>
    }
}