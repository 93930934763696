import React from "react";
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Geocode from "react-geocode";
import $ from "jquery";


const LoadingContainer =(props) =>  (<div className="text-center"><p>Cargando...</p></div>);

export class MapContainer extends React.Component {
  _center = null
_mounted;
    constructor(props){

        super(props);

        this.state = {
            marker:{
              x:23.7361694,
              y:-105.3983562
            },
            geocoder:null,
            address:"",
            render:true,
            center:{
                x:23.7361694,
                y:-105.3983562
            },
            style:{
              width:"600px"
            }
        }
    }

    componentDidMount(){
        this._mounted = true;
       Geocode.setApiKey("AIzaSyDybIuhJCo4f30KW_e5sNqIwEUuF8FNsPU");

        if(this._mounted){
          if(this.props.position !== null){
            if(this._mounted){
              this.setState({
                marker:{
                  x:Number(this.props.position.lat),
                  y:Number(this.props.position.lng)
                }
              })
            }
          }
        }

       // this.getPosition();

        let  myFunction = (x)=>{
          if (x.matches) { // If media query matches
            if(this._mounted){
              this.setState({
                style:{
                  width:"350px"
                }
              })
            }
          } else {
            if(this._mounted){
              this.setState({
                style:{
                  width:"600px"
                }
              })
            }
          }
        }
        
        var x = window.matchMedia("(max-width: 700px)")
        myFunction(x) // Call listener function at run time
        x.addListener(myFunction)
    }

    componentWillUnmount(){
        this._mounted = false;
    
    }

     geocodeLatLng =(position,geocoder, map, infowindow) => {
      //var input = document.getElementById('latlng').value;
      //var latlngStr = input.split(',', 2);
   /*  var latlng = {lat: parseFloat(this.state.marker.x), lng: parseFloat(this.state.marker.y)};
      geocoder.geocode({'location': latlng}, (results, status) =>{
        if (status === 'OK') {
          if (results[0]) {
            if(this._mounted){
              this.setState({
                address:results[0].formatted_address
              })
              this.props.setAddress(results[0].formatted_address);
            }*/
          //  map.setZoom(11);
           /* var marker = new google.maps.Marker({
              position: latlng,
              map: map
            });*/
           // infowindow.setContent(results[0].formatted_address);
            //infowindow.open(map, marker);
            
        /*  } else {
            //window.alert('No results found');
          this.props.addToast("Resultados no encontrados");
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      });
      */

     Geocode.fromLatLng(position.x, position.y).then(
      response => {
        const address = response.results[0].formatted_address;
        console.log(address);
        this.props.setAddress(response.results[0].formatted_address);
        this.props.setCoords({x:position.x, y:position.y})
        this.props.validate();
      },
      error => {
        console.error(error);
        this.props.addToast("Algo salió mal");
      }
    );

    /*$.ajax({url:"https://mudatodo-server.herokuapp.com/getAddress", crossDomain:true, 
    data:{
        lat:this.state.marker.x,
        lng:this.state.marker.y
    },
   method:"POST",
success:(response) =>{
   console.log(response);
  
}, error:(err) => {
   
    this.props.addToast("Algo salió mal");
}})*/

    }


     codeLatLng =(lat, lng) => {
       let geocoder = this.props.google.maps.Geocoder();
        var latlng = new this.props.google.maps.LatLng(lat, lng);
        geocoder.geocode({
          'latLng': latlng
        }, function (results, status) {
          if (status === this.props.google.maps.GeocoderStatus.OK) {
            if (results[1]) {
              console.log(results[1]);
              alert(results[1]);
            } else {
              alert('No results found');
            }
          } else {
            alert('Geocoder failed due to: ' + status);
          }
        });
      }

   /* getPosition = () => {
        navigator.geolocation.getCurrentPosition((position) => {
          var geocoder = new this.props.google.maps.Geocoder;
            if(this._mounted){
              this._center = {x:position.coords.latitude, y:position.coords.longitude}
                this.setState({
                  geocoder:geocoder,
                    marker:{
                        x:position.coords.latitude,
                        y:position.coords.longitude
                    },
                    center:{
                        x:position.coords.latitude,
                        y:position.coords.longitude
                    },
                    render:true
                }, () => {
                   
                    this.props.setCoords({x:this.state.marker.x, y:this.state.marker.y})
                    
                    this.geocodeLatLng(geocoder);
                    //this.codeLatLng(this.state.marker.x, this.state.marker.y);
                })
            }
        } , (err) => {
            if(err){
                this.props.addToast("Algo salió mal");
            }
        })
    }*/

    componentWillReceiveProps(nextProps) {
      if(nextProps.position !== null){
        if(this._mounted){
          this.setState({
            marker:{
              x:Number(nextProps.position.lat),
              y:Number(nextProps.position.lng)
            }
          })
        }
      }
    }
    render() {

        

        return (
          <>{this.state.center.x !== 0?<Map
         
          containerStyle={{
            position: 'relative',  
            width: this.state.style.width,
            height: '200px'
          }}          
        
          initialCenter={new this.props.google.maps.LatLng({lat: this.state.marker.x, lng: this.state.marker.y})}
         
          center={new this.props.google.maps.LatLng({lat: this.state.marker.x, lng: this.state.marker.y})}
          onClick={(mapProps, maps, clickEvent) => {
            this.props.unvalidate();
            
              this.setState({
                  marker:{x:clickEvent.latLng.lat(),
                         y:clickEvent.latLng.lng()
                   }
              }, () => {
               
                this.geocodeLatLng({x:clickEvent.latLng.lat(),
                  y:clickEvent.latLng.lng()
            });
              })
          }}
          style={{
              width:"100%",
              height:"200px",
          }}
   
          google={this.props.google} zoom={14}>
    
            <Marker 
            
            position={new this.props.google.maps.LatLng({lat: this.state.marker.x, lng: this.state.marker.y})}
                    name={'Current location'} />
    
            
          </Map>:null}</>
        );
      }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyDybIuhJCo4f30KW_e5sNqIwEUuF8FNsPU",
    LoadingContainer:LoadingContainer,

  })(MapContainer)