import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/analytics";

var firebaseConfig = {
    apiKey: "AIzaSyAA4nYA2ww-4-t0-is1tBWu1xHLGB-1cT8",
    authDomain: "mudatodo-8f658.firebaseapp.com",
    databaseURL: "https://mudatodo-8f658.firebaseio.com",
    projectId: "mudatodo-8f658",
    storageBucket: "mudatodo-8f658.appspot.com",
    messagingSenderId: "1090689672261",
    appId: "1:1090689672261:web:37d0ae5b6c58286c32af2c",
    measurementId: "G-LRTCRV06XE"
  };

  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  export default firebase;