import React from "react";
import logo_mudanzas_1 from "../../res/logo_mudanzas_1.png";

export default class ModalClientRequest extends React.Component {
    _mounted;

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <div className="modal-home-wrapper">
            <button className="btn-close-modal " onClick={this.props.closeModal}>
                <i className="material-icons align-middle">clear</i>
            </button>
            <div className="modal-home-company">
                <div className="flex-center">
                    <img src={this.props.mudaPartner.photoURL !== "" && this.props.mudaPartner.photoURL !== undefined?this.props.mudaPartner.photoURL:logo_mudanzas_1} width="150px"/>
                </div>

                 {this.props.mudaPartner !== null?<p className="text-center modal-home-text mt-4">Oferta ID {this.props.userData.service}</p>:<div className="mt-3"><div className="spinner-border"></div></div>}

                 {this.props.mudaPartner !== null?<p className="text-center modal-home-text mt-3"><b>{this.props.mudaPartner.company}</b></p>:<div className="mt-3"><div className="spinner-border"></div></div>}

                 {this.props.mudaPartner !== null?<p className="mt-3 text-center modal-home-text">Teléfono: {this.props.mudaPartner.phone}</p>:<div className="mt-3"><div className="spinner-border"></div></div>}

                {this.props.mudaPartner !== null?<p className=" mt-3 text-center modal-home-text">Email {this.props.mudaPartner.email}</p>:<div className="mt-3"><div className="spinner-border"></div></div>}

                <div className="action-text" onClick={this.props.changeScreen}>
                    <h4 className="text-center"><b>CERRAR</b></h4>
                </div>
            </div>
        </div>
    }
}