import React from "react";

class MudatdoInsurance extends React.Component {
    _mounted;

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;

        window.document.body.style.overflow = "hidden";
    }

    componentWillUnmount(){
        this._mounted = false;

        window.document.body.style.overflow = "visible"
    }

    render(){
        return <div className="modal-wrapper fade-in">
            <button className="btn btn-close-modal" onClick={() => {
                this.props.close();
            }}>
                <i className="material-icons align-middle">clear</i>
            </button>

            <div className="modal-content">
                <div>
                    <h2 className="text-center">SEGURO MUDATODO</h2>

                    <p className="mt-4 text-center">
                    Riesgos cubiertos:<br/><br/>
Robo: Cubre los bienes asegurados contra la falta de entrega del contenido 
de uno o varios bultos por entero por robo, quedando estipulado que no habrá 
responsabilidad para la institución por la falta de contendió en los bultos. 
Riesgos ordinarios de tránsito (R.O.T.): Daños causados a consecuencia 
de un accidente. <br/><br/>
Daños materiales a los bienes causados por incendio, rayo y explosión; o por 
caída de aviones, auto ignición, colisión, volcadura o descarrilamiento del 
vehículo u otro medio de transporte empleado incluyendo hundimiento o 
rotura de puentes. <br/><br/>
NOTA: SOLO TENDRA EFECTO LA COBERTURA DE R.O.T. CUANDO EL MEDIO DE 
TRANSPORTE SUFRA UN ACCIDENTE Y A CONSECUENCIA DEL MISMO SE DAÑE LA 
MERCANCÍA. NO PROCEDERÁ LA RECLAMACIÓN CUANDO SE DAÑE LA MERCANCÍA 
POR FALTA DE EMPAQUE, EMBALAJE O MAL ESTIBADO. <br/><br/>
También se cubrirán ratería, pillería y hurto. <br/><br/>
Bodega a bodega: Cubre los bienes desde la bodega u oficina del remitente 
en que inicia su tránsito para su curso normal de viaje y cesa en la terminación 
del tránsito de los bienes asegurados a su llegada a la bodega u oficina del 
consignatario, entre los puntos desde origen, destino y riesgos cubiertos 
indicados en la póliza 
En menajes de casa, bienes usados, cuando únicamente se asegure la 
continuación del viaje o el producto carezca de embalaje profesional solo se 
darán las coberturas de robo y ROT
                    </p>
                </div>
            </div>
        </div>
    }
}

export default MudatdoInsurance;