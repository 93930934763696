import React from "react";
import QuestionBlock from "../QuestionBlock";
import ModalClientRequest from "../ModalClientRequest";
import ModalCompany from "../ModalCompany";
import ModalCompanyDetails from "../ModalCompanyDetails";
import ModalRating from "../ModalRating";

class MyAccount extends React.Component {
    _mounted;
    _leadListener;

    constructor(props){
        super(props);

        this.state = {
            modal:null,
            leadData:null
        }
    }

    componentDidMount(){
        this._mounted = true;

        if(this.props.rateMudapartner !== false){
            console.log(this.props.rateMudapartner)
            if(this._mounted){
                this.setState({
                    modal:3
                })
            }
        }
    }

    componentWillUnmount(){
        this._mounted = false
    }

    render(){
        return <div className="modal-wrapper fade-in">
            <button className="btn btn-close-modal" onClick={() => {
                        this.props.close();
            }}>
                <i className="material-icons align-middle">clear</i>
            </button>

            {this.state.modal === 0?<ModalClientRequest
            addToast={this.props.addToast}
            closeModal={() => {
                if(this._mounted){
                    this.setState({
                        modal:null
                    })
                }
            }}
             lead={this.props.leadData}
             userData={this.props.userData}
            />:null}

            {this.state.modal === 1?<ModalCompany
            changeScreen={() => {
                this.props.changeScreen(4)
            }}
            closeModal={() => {
                if(this._mounted){
                    this.setState({
                        modal:null
                    })
                }
            }}
            />:null}

{this.state.modal === 2?<ModalCompanyDetails
userData={this.props.userData}
mudaPartner={this.props.mudaPartner}
addToast={this.props.addToast}
            changeScreen={() => {
                if(this._mounted){
                    this.setState({
                        modal:null
                    })
                }
            }}
            closeModal={() => {
                if(this._mounted){
                    this.setState({
                        modal:null
                    })
                }
            }}
            />:null}

{this.state.modal === 3?<ModalRating
userData={this.props.userData}
mudaPartner={this.props.mudaPartner}
addToast={this.props.addToast}
            changeScreen={() => {
                if(this._mounted){
                    this.setState({
                        modal:null
                    })
                }
            }}
            closeModal={() => {
                if(this._mounted){
                    this.setState({
                        modal:null
                    })
                }
            }}
            />:null}

            <div className="modal-content home-main">

            <div className="flex-center"><h1 className="text-center main-header"><b>Hola</b> {this.props.name}</h1></div>


<div className="row mt-5 mx-0">
    <div className="col-sm-6  mt-3">
        <div className="flex-center">
            <i className="material-icons align-middle step-icon">sentiment_satisfied_alt</i>
        </div>
        <div className="flex-center mt-2 ">
            <button className="btn btn-custom-1 btn-block" onClick={() => {
                
                if(this._mounted){
                    this.setState({
                        modal:0
                    })
                }
             
            }}>
                <span>MI SOLICITUD</span>
            </button>
        </div>
    </div>
    <div className="col-sm-6 mt-3">
        <div className="flex-center">
            <div className="step-circle">2</div>
        </div>

        <div className="flex-center mt-2 ">
            <button className={`btn btn-block ${this.props.stage === 0?"btn btn-custom-1":"btn-selectable"}`} onClick={() => {
               this.props.close();
            }}>
                <span>VER PROPUESTAS</span>
            </button>
        </div>
    </div>
</div>

<div className="row mt-5 mx-0 mt-3">
    <div className="col-sm-6 ">
        <div className="flex-center ">
          <div className="step-circle">3</div>
        </div>
        <div className="flex-center mt-2 ">
            <button className={`btn btn-block ${this.props.stage === 1?"btn btn-custom-1":"btn-selectable"}`} onClick={() => {
                if(this.props.stage === 0){
                    if(this._mounted){
                        this.setState({
                            modal:1
                        })
                    }
                }
                if(this.props.stage === 1){
                    if(this._mounted){
                        this.setState({
                            modal:2
                        })
                    }
                }
            }}>
                <span>MUDAPARTNER</span>
            </button>
        </div>
    </div>
    <div className="col-sm-6  mt-3">
        <div className="flex-center">
            <div className="step-circle">4</div>
        </div>

        <div className="flex-center mt-2 ">
            <button className={`btn btn-block ${this.props.stage === 1?"btn btn-custom-1":"btn-selectable"}`} onClick={() => {
                if(this.props.stage === 1){
                    if(this._mounted){
                        this.setState({
                            modal:3
                        })
                    }
                }
            }}>
                <span>CALIFICAR SERVICIO</span>
            </button>
        </div>
    </div>
</div>

<div className="flex-center  mt-4">
    <div>
    <div className="flex-center">
            <i className="material-icons align-middle step-icon">error</i>
        </div>
        <div className="flex-center mt-2 ">
            <a className="btn btn-selectable btn-block" target="_blank" href={`https://api.whatsapp.com/send?phone=5219983047709&text=Hola,%20quiero%20dar%20seguimiento%20a%20mi%20solicitud%20de%20mudanza,%20mi%20nombre%20es%20${this.props.name}`}>
                <span>AYUDA POR WHATSAPP</span>
            </a>
        </div>
    </div>
</div>
            </div>
        </div>
    }
}

export default MyAccount;