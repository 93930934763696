import React from "react";
import muda_asistente_1 from "../../res/muda_asistente_1.png";

export default class RecolectionDistanceEdit extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        this.state = {
            screen:0
        }
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <div className={`fade-in mt-1 ${this.props.fade?"fade-out":""}`}>
            <button className=" btn btn-back-left" onClick={() => {
                this.props.back();
            }}>
                <i className="material-icons align-middle">arrow_back</i>
            </button>
           <div className="muda-planner flex-center">
                   <div className="muda-planner-img mr-3">
                       <img src={muda_asistente_1} />
                   </div>
                   <div className="muda-planner-info">
                       <h5>Eduardo</h5>

                       <div className="muda-planner-stars flex-left mt-2">
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle mr-1">grade</i>
                           <i className="material-icons align-middle">grade</i>
                       </div>

                       <p className="mt-2">MudaPlanner</p>
                   </div>
               </div>

        <div className="flex-center"><h1 className="main-header text-center mt-5">{this.props.header}</h1></div>

             <div className="flex-center">
               <div className="login-form mt-4">
                   <div className="form-group">
                   <div className="input-group mb-3 cursor-pointer" onClick={()=> {
                       this.props.handleSelection(0)
                   }}>
                       <input className="form-control cursor-pointer"  readOnly={true} placeholder="A menos de 20 mts de la entrada" type="text"/>
                       {this.props.firstDistance === true?<div className="input-group-append">
                        <span className="input-group-text"><i className="material-icons align-middle checked">check_circle</i></span>
                        </div>:null}
                       </div>
                   </div>

                   <div className="form-group">
                   <div className="input-group mb-3 cursor-pointer" onClick={()=> {
                       this.props.handleSelection(1)
                   }}>
                       <input className="form-control cursor-pointer"  readOnly={true} placeholder="A más de 50 y menos de 100 mts" type="text"/>
                       {this.props.secondDistance === true?<div className="input-group-append">
                        <span className="input-group-text"><i className="material-icons align-middle checked">check_circle</i></span>
                        </div>:null}
                       </div>
                   </div>

                   <div className="form-group">
                   <div className="input-group mb-3 cursor-pointer" onClick={()=> {
                       this.props.handleSelection(2)
                   }}>
                       <input className="form-control cursor-pointer"  readOnly={true} placeholder="A más de 100 mts" type="text"/>
                       {this.props.thirdDistance === true?<div className="input-group-append">
                        <span className="input-group-text"><i className="material-icons align-middle checked">check_circle</i></span>
                        </div>:null}
                       </div>
                   </div>

                 
                   <div className="flex-center">

                   <button className="btn btn-selectable mr-2" onClick={() => {
                        this.props.changeScreenOmit(1)
                    }}>
                        <span>OMITIR</span>
                    </button>

                   <button className={`btn ${this.props.valid?"btn-custom-1":"btn-disabled"} mt-4 btn-block`} onClick={() => {
                       if(this.props.valid){
                           this.props.changeScreen(0);
                       }else {
                           this.props.addToast("Por favor seleccione una de las opciones para continuar");
                       }
                   }}>
                       <span>CONTINUAR</span>
                   </button>

                   </div>

               </div>
               </div>
            </div>

                }
            }