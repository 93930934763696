import React from "react";



export default class QuestionBlock extends React.Component {
    _mounted;

    constructor(props){
        super(props);

        this.state = {
            isOpen:false,
            fade:false,
            id:""
        }
    }

    componentDidMount(){
        this._mounted = true;

    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <div className={`question-block ${this.state.isOpen?"question-selected":""}`} onClick={() => {
            if(this._mounted){
              
                this.setState({
                    isOpen:!this.state.isOpen
                })
          
            }
            
        }}>
            <p>{this.props.question}</p>

            {this.state.isOpen?<div  className={`answer-block block-in ${this.state.fade?"block-out":""}`}>
               <p>{this.props.answer}</p>
            </div>:null}
        </div>
    }
}